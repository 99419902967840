<script setup lang="ts">
import Panel from 'primevue/panel';
import 'primeicons/primeicons.css';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { QuillEditor } from '@vueup/vue-quill';
import type { QuillEditor as QuillEditorType } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import FileUpload, { type FileUploadSelectEvent } from 'primevue/fileupload';
import { onBeforeMount, ref } from 'vue';
import InputNumber from 'primevue/inputnumber';
import axios, { type AxiosResponse } from 'axios';
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Toast, { type ToastMessageOptions } from 'primevue/toast';
import router from '../router';
import { useI18n } from 'vue-i18n';
import GalleryComponent from '@/components/GalleryComponent.vue';
import Dialog from 'primevue/dialog';
import Select from 'primevue/select';
import { v4 as uuidv4, v4 } from 'uuid';

/** TYPES */
interface CustomFile extends File {
  objectURL?: string;
}

type ItemType = {
  MonitorSerialNumber: string;
  relatedSince: Date;
  updatedAt: Date;
  createdAt: Date;
  Maintenance: boolean;
  Monitor: number;
  Active: boolean;
};

/** DATA */
const maxLength = 25;
const remainingCharacters = ref(maxLength);
const route = useRoute();
const toast = useToast();
const { t } = useI18n();

const monitorCounter = ref<number>(0);
const monitors = ref([]);
const monitorInformation = ref();
const stepTitle = ref<string>('');
const instruction = ref<typeof QuillEditorType | null>(null);
const stepNumber = ref<number>();
const stepId = route.params.stepId; //! only if you want to edit a step
const step = ref();
const relatedMonitor = ref();
const showPictureBib = ref<boolean>(false);

const fileInput = ref<HTMLInputElement | null>(null);
const imageBlob = ref(null);
const file = ref();
// const video = ref(null);
// const canvas = ref(null);
const isMobile = ref(false);
// let currentStream;

/** METHODS */
const updateRemainingCharacters = () => {
  remainingCharacters.value = maxLength - stepTitle.value.length;
};

const checkIfMobile = () => {
  const userAgent = navigator.userAgent;
  isMobile.value = /Mobi|Android|iPhone|iPad/i.test(userAgent);
};

/*
const switchCamera = () => {
  const newFacingMode =
    currentStream.getVideoTracks()[0].getSettings().facingMode === 'environment'
      ? 'user'
      : 'environment';
  if (currentStream) {
    currentStream.getTracks().forEach((track) => track.stop());
  }
  startVideo(newFacingMode);
};


const startVideo = async (facingMode = 'environment') => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode }
    });
    video.value.srcObject = stream;
    currentStream = stream;
  } catch (err) {
    console.error('Fehler beim Zugriff auf die Kamera:', err);
  }
};


/*
const capture = () => {
  const context = canvas.value.getContext('2d');
  context.drawImage(video.value, 0, 0, canvas.value.width, canvas.value.height);
  canvas.value.toBlob((blob: Blob) => {
    const imageName = `${uuidv4()}.png`;
    imageBlob.value = new File([blob], imageName, { type: 'image/png' });
    console.log(imageBlob.value);
    const url = URL.createObjectURL(blob);
    file.value = url;
  }, 'image/png');
};
*/

const onFileChange = (event) => {
  const picture = event.files?.[0];

  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      file.value = e.target?.result as string;
    };
    imageBlob.value = picture;
    console.log(imageBlob.value);
    reader.readAsDataURL(picture);
  }
};

const selectFile = () => {
  fileInput.value?.click();
};

const totalSize = ref(0);
const totalSizePercent = ref(0);

const showToast = (
  sev: ToastMessageOptions['severity'],
  sum: string,
  det: string
) => {
  toast.add({
    severity: sev,
    summary: sum,
    detail: det,
    life: 3000
  });
};

const handlePictureSelect = (selectedImage: string) => {
  file.value = selectedImage;
  const base64WithoutPrefix = selectedImage.split(',')[1];
  const byteCharacters = atob(base64WithoutPrefix);
  const byteNumbers = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const blob = new Blob([byteNumbers], { type: 'image/png' });
  const newFile: CustomFile = new File([blob], `${uuidv4()}.png`, {
    type: blob.type
  });

  newFile.objectURL = URL.createObjectURL(blob);

  imageBlob.value = [newFile][0];
  showPictureBib.value = false;
};

async function getSteps() {
  try {
    const recipeId = route.params.recipeId;
    const res = await axios.get(`/api/recipe/${recipeId}`);
    if (res.status === 200) {
      if (res.data.steps.length !== 0) {
        let step: number[] = res.data.steps.map((s) => {
          return s.Step;
        });
        const lastStep = Math.max(...step);
        stepNumber.value = lastStep + 1;
      } else {
        stepNumber.value = 1;
      }
    } else {
      showToast('error', 'Error', t('admin.dashbaord.errors.errorRecipe'));
    }
  } catch (e) {
    console.log(e);
  }
}

async function prepareInformations() {
  monitorInformation.value = [];
  const response: AxiosResponse = await axios.get('/api/monitors/all');
  if (response.data) {
    response.data.forEach((item: ItemType) => {
      monitorInformation.value.push({
        serialNumber: item.MonitorSerialNumber,
        related: item.Monitor,
        status: item.Active,
        maintenance: item?.Maintenance,
        knownSince: item?.createdAt,
        addedSince: item.relatedSince
      });
    });
  }

  //* reset
  monitorCounter.value = 1;
  monitors.value = [];

  //* anzahl der monitore
  monitorInformation.value.forEach((monitor) => {
    if (monitor.related)
      monitors.value.push({
        name: monitorCounter.value++,
        value: monitor.related
      });
  });
}

function clearFileZone() {
  // startVideo();
  file.value = null;
  step.value = null;
  imageBlob.value = null;
}

async function checkIfMaxStepsReached() {
  try {
    const recipeId = route.params.recipeId;
    const res = await axios.get(`/api/recipe/${recipeId}`);

    if (res.status === 200) {
      const steps = res.data.steps;
      const relatedMonitorId = relatedMonitor.value;

      const stepsWithSameMonitor = steps.filter(
        (step) => step.MonitorNumber === relatedMonitorId.name
      );

      if (stepsWithSameMonitor.length >= 5) {
        showToast(
          'warn',
          t('toastCallback.warn'),
          t('admin.dashboard.maxStepsReached')
        );
        return true;
      }
    } else {
      showToast('error', 'Error', t('admin.dashbaord.errors.errorRecipe'));
    }
  } catch (e) {
    console.error(e);
  }

  return false;
}

async function createStep() {
  if (!(await checkIfMaxStepsReached())) {
    const formdData = new FormData();
    const recipeId = route.params.recipeId;
    if (
      imageBlob.value &&
      instruction.value?.getQuill().root.innerHTML &&
      stepTitle.value.trim() &&
      stepNumber.value &&
      relatedMonitor.value
    ) {
      formdData.append('title', stepTitle.value);
      formdData.append('step', stepNumber.value.toString());
      formdData.append('text', instruction.value?.getQuill().root.innerHTML);
      formdData.append('picture', imageBlob.value);
      formdData.append('relatedMonitor', relatedMonitor.value.name);

      await axios
        .post(`/api/recipe/${recipeId}/steps`, formdData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then((res) => {
          if (res.status === 201) {
            showToast('success', 'Success', t('admin.step.success.created'));
            router.push({ name: 'myRecipe', params: { recipeId } });
          }
        })
        .catch((e) => {
          if (e) {
            showToast('error', 'Error', t('admin.step.errors.createStep'));
          }
        });
    } else {
      showToast('info', 'Info', t('admin.step.errors.emptyFields'));
    }
  }
}

function backToRecipeView() {
  const recipeId = route.params.recipeId;
  router.push({ name: 'myRecipe', params: { recipeId } });
}

function editStep() {
  const formdData = new FormData();
  const recipeId = route.params.recipeId;
  if (
    file.value &&
    instruction.value?.getQuill().root.innerHTML &&
    stepTitle.value.trim() &&
    stepNumber.value &&
    relatedMonitor.value
  ) {
    formdData.append('title', stepTitle.value);
    formdData.append('text', instruction.value?.getQuill().root.innerHTML);
    formdData.append('relatedMonitor', relatedMonitor.value?.value);

    if (imageBlob.value) {
      formdData.append('picture', imageBlob.value);
    }

    axios
      .patch(`/api/recipe/${recipeId}/steps/${stepId}`, formdData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((res) => {
        if (res.status === 200) {
          showToast('success', 'Success', t('admin.step.success.updated'));
          router.push({ name: 'myRecipe', params: { recipeId } });
        }
      })
      .catch((e) => {
        if (e) {
          showToast('error', 'Error', t('admin.step.errors.editStep'));
        }
      });
  } else {
    showToast('info', 'Info', t('admin.step.errors.emptyFields'));
  }
}

/** HOOKS */
onBeforeMount(async () => {
  await prepareInformations();
  // startVideo();
  checkIfMobile();

  if (stepId) {
    const res = await axios.get(`/api/recipe/steps/${stepId}`);
    step.value = res.data;

    stepTitle.value = step.value.Title;
    instruction.value.getQuill().root.innerHTML = step.value.Text;
    stepNumber.value = step.value.Step;
    file.value = step.value.Picture;

    if (res.data.MonitorNumber) {
      const relatedMonitorObj = monitors.value.find(
        (item) => item.name === res.data.MonitorNumber
      );

      if (relatedMonitorObj) {
        relatedMonitor.value = relatedMonitorObj;
      } else {
        console.warn(
          t('adnmin.step.errors.monitorOptions'),
          res.data.MonitorNumber
        );
      }
    }
  } else {
    await getSteps();
  }
});
</script>

<template>
  <div class="step">
    <Toast></Toast>
    <div class="content">
      <Panel style="height: 100%">
        <template #header>
          <div class="flex items-center gap-2">
            <span v-if="!stepId" class="font-bold">{{
              t('admin.step.createStep')
            }}</span>
            <span v-else class="font-bold">{{ t('admin.step.editStep') }}</span>
          </div>
        </template>
        <div class="panel-content">
          <div class="file">
            <div class="picture-action" v-if="!file">
              <FileUpload
                v-model="file"
                :multiple="true"
                accept="image/*"
                @select="onFileChange"
              >
                <template #header="{ chooseCallback, uploadCallback }">
                  <div
                    class="flex flex-wrap justify-between items-center flex-1 gap-4"
                  >
                    <div class="flex gap-2">
                      <Button
                        @click="chooseCallback()"
                        icon="pi pi-images"
                        rounded
                        outlined
                        severity="contrast"
                      ></Button>
                    </div>
                  </div>
                </template>
                <template #empty>
                  <div class="items flex items-center justify-center flex-col">
                    <i
                      class="pi pi-cloud-upload !border-2 !rounded-full !p-8 !text-4xl !text-muted-color"
                    />
                    <p class="mt-6 mb-0">
                      Drag and drop files to here to upload.
                    </p>
                  </div>
                </template>
              </FileUpload>
              <div class="buttons">
                <Button
                  icon="pi pi-images"
                  :label="t('admin.dashboard.pictureBib')"
                  serverity="secondary"
                  @click="showPictureBib = true"
                  style="width: 100%"
                >
                </Button>
              </div>
            </div>
            <div v-if="file" class="image">
              <Button
                icon="pi pi-times"
                class="deleteIcon"
                rounded
                severity="danger"
                @click="clearFileZone"
              ></Button>
              <img
                v-if="file"
                :src="file"
                alt="Vorschau"
                style="max-width: 100%; margin-top: 10px"
              />
            </div>
          </div>
          <div class="instruction">
            <span>{{ t('admin.step.step') }}</span>
            <InputNumber
              v-if="!stepId"
              v-model="stepNumber"
              :placeholder="t('admin.step.step')"
            />
            <InputNumber
              v-else
              v-model="stepNumber"
              :placeholder="t('admin.step.step')"
              disabled
            />
            <span>{{ t('admin.step.title') }}</span>
            <div class="input-container">
              <InputText
                :maxlength="maxLength"
                v-model="stepTitle"
                @input="updateRemainingCharacters"
                class="p-inputtext"
                :placeholder="t('admin.step.title')"
              />
              <span class="char-counter"
                >{{ remainingCharacters }}/{{ maxLength }}</span
              >
            </div>
            <span>{{ t('admin.dashboard.monitor') }}</span>
            <Select
              v-model="relatedMonitor"
              :options="monitors"
              :optionLabel="t('admin.dashboard.name')"
              :placeholder="t('admin.monitorConfiguration.chooseMonitor')"
              class="w-full md:w-56"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex items-center">
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="flex items-center">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Select>
            <span>{{ t('admin.step.instruction') }}</span>
            <div class="editor">
              <QuillEditor ref="instruction" contentType="text" theme="snow" />
            </div>
          </div>
        </div>
        <template #footer>
          <div class="flex flex-wrap items-center justify-between gap-4">
            <div class="add-step-button">
              <div class="editSteps">
                <Button
                  v-if="!stepId"
                  :label="t('admin.step.createStep')"
                  @click="createStep"
                />
                <Button
                  v-else
                  :label="t('admin.monitorConfiguration.deletePopupSave')"
                  @click="editStep"
                />
                <Button
                  :label="t('admin.monitorConfiguration.deletePopupCancel')"
                  @click="backToRecipeView"
                  severity="secondary"
                />
              </div>
            </div>
          </div>
        </template>
        <template #icons> </template>
      </Panel>
    </div>
  </div>
  <Dialog
    class="newScrollbar"
    v-model:visible="showPictureBib"
    maximizable
    modal
    :header="t('admin.dashboard.pictureBib')"
    :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <GalleryComponent @onSelect="handlePictureSelect" />
  </Dialog>
</template>

<style scoped>
.step {
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  margin-left: 2rem;
  margin-right: 2rem;
  gap: 3rem;
  height: 100%;
}
.switch-camera {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
}

.take-photo {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.panel-content {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 2rem;
}

@media (max-width: 1240px) {
  .panel-content {
    flex-direction: column;
  }

  .picture-actions {
    width: 100% !important;
  }
}

.picture-action {
  width: 100%;
}

.file {
  width: 50%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .deleteIcon {
    margin: 1%;
    position: absolute;
  }
}

video {
  border: 1px solid black;
  width: 100%;
  max-width: 640px;
  height: auto;
}

.file img {
  border-radius: 1rem;
  height: 25rem;
  max-width: 100%;

  object-fit: contain;
  display: block;
  margin: auto;
  transition: transform 0.3s;
}

.instruction {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 2rem;
}

.add-step-button {
  display: flex;
  justify-content: end;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;
}

.editSteps {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: end;
}

.input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.p-inputtext {
  padding-right: 40px;
  width: 100%;
  height: 100%;
}

.char-counter {
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 0.8em;
  color: #888;
  pointer-events: none;
}

.items {
  border: 1px dotted;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: clamp(2rem, 8vw, 15rem);

  i {
    zoom: 2;
  }
}
</style>
