import { ref, onMounted, onUnmounted } from 'vue';
import { io } from 'socket.io-client';
import axios from 'axios';

export function useSocket() {
  const socket = ref(null);
  const baseUrl = import.meta.env.VITE_APP_BASE_URL ?? import.meta.env.BASE_URL;

  onMounted(() => {
    socket.value = io(baseUrl);

    socket.value.on('connect', () => {
      console.log('Websocket connection established!');
      socket.value.emit('subscribeToRecipeUpdates');
    });

    socket.value.on('recipeUpdated', async () => {
      const recipe = await axios.get('/api/recipe/active');
      return recipe;
    });

    socket.value.on('monitorUpdated', async (data) => {
      return await axios.get(`/api/monitors/one/${data.serialNumber}`);
    });

    socket.value.on('momitorCreated', async () => {});
  });

  onUnmounted(() => {
    if (socket.value) {
      socket.value.disconnect();
    }
  });

  return { socket };
}
