<script setup lang="ts">
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Password from 'primevue/password';
import Button from 'primevue/button';
import { onMounted, ref } from 'vue';
import axios, { type AxiosResponse } from 'axios';
import type { InitialAdmin } from '../interfaces/admin';
import { useRouter } from 'vue-router';
import Toast, { type ToastMessageOptions } from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

/** DATA */
const name = ref('');
const password = ref('');
const visible = ref<boolean>(false);
let admin: InitialAdmin;

const { t } = useI18n();
const router = useRouter();
const toast = useToast();

/** METHODS */
const showToast = (
  sev: ToastMessageOptions['severity'],
  sum: string,
  det: string
) => {
  toast.add({
    severity: sev,
    summary: sum,
    detail: det,
    life: 3000
  });
};

const submit = async () => {
  if (name.value.trim() && password.value.trim()) {
    await axios
      .get('/api/auth/login', {
        params: {
          name: name.value,
          password: password.value
        }
      })
      .then((res) => {
        if (res.status === 200) {
          router.push({ name: 'home' });
        }
      })
      .catch((err) => {
        if (err) {
          showToast('error', 'Error', t('login.errors.incorrect'));
        }
      });
  } else {
    showToast('error', 'Error', t('login.errors.emptyInputs'));
  }
};

/** HOOKS */
onMounted(async () => {
  const response: AxiosResponse = await axios.get('/api/user/service-admin');
  if (response.data) {
    admin = response.data;
    visible.value = true;
  } else {
    visible.value = false;
  }
});
</script>

<template>
  <div class="login-layout">
    <Toast></Toast>
    <div class="login-mask">
      <div class="head">
        <div class="logo">
          <img class="logo-svg" src="../assets/logo-blue.svg" alt="Logo" />
        </div>
        <div class="heading">
          <h3>{{ t('login.login') }}</h3>
        </div>
      </div>
      <div class="body">
        <div class="inputs">
          <div class="name">
            <InputText
              @keyup.enter="submit()"
              class="input-fields"
              type="text"
              v-model="name"
              :placeholder="t('login.username')"
            />
          </div>
          <div class="password">
            <Password
              @keyup.enter="submit()"
              class="input-fields"
              v-model="password"
              toggleMask
              :feedback="false"
              :placeholder="t('login.password')"
            />
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="signIn">
          <Button
            class="login-button"
            :label="t('login.signIn')"
            severity="primary"
            raised
            @click="submit()"
          />
        </div>
      </div>
    </div>
    <Dialog
      v-model:visible="visible"
      modal
      :style="{ width: '25rem' }"
      :closable="true"
      header="Initial Account"
    >
      <p>{{ admin.Name }}</p>
      <p>{{ admin.Password }}</p>
    </Dialog>
  </div>
</template>

<style scoped>
.input-fields {
  width: 100%;
  border-color: #0e4ca8;
}
.error {
  color: #e74d3d;
}
.p-inputtext:enabled:focus {
  border-color: #0e4ca8;
}
.login-button {
  width: 235px;
  background: #0e4ca8;
  border: 1px solid #0e4ca8;
}
.p-button:not(:disabled):hover {
  background: rgb(36, 61, 203);
}
.login-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.login-mask {
  justify-content: center;
  text-align: center;
  background-color: white;
  height: 30rem;
  width: 25rem;
  border-radius: 5%;
}
.head {
  padding-top: 2rem;
}
.logo-svg {
  width: 15rem;
}
.inputs {
  display: flex;
  flex-direction: column;
}
.body {
  padding-top: 2rem;
  display: grid;
  justify-content: center;
  padding-bottom: 6rem;
}
.name {
  width: 100%;
  padding-bottom: 0.2rem;
}
.password {
  width: 100%;
}
.signIn {
  display: flex;
  justify-content: center;
}
</style>
