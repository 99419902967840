<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { onMounted, ref } from 'vue';
import { useSocket } from '@/assets/useSocket';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import AutoCompleteComponent from '@/components/AutoCompleteComponent.vue';
import { useToast } from 'primevue/usetoast';
import type { ToastMessageOptions } from 'primevue/toast';
import axios, { type AxiosResponse } from 'axios';
import moment from 'moment';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import Panel from 'primevue/panel';
import Chip from 'primevue/chip';
import OverlayBadge from 'primevue/overlaybadge';

/** TYPES */
type ItemType = {
  MonitorSerialNumber: string;
  relatedSince: Date;
  updatedAt: Date;
  createdAt: Date;
  Maintenance: boolean;
  Monitor: number;
  Active: boolean;
  Confirmed: boolean;
};

type MonitorType = {
  serialNumber: string;
  related: number;
  status: boolean;
  maintenance: boolean;
  knownSince: Date;
  addedSince: Date;
  confirmed: boolean;
};

/** DATA */
const { socket } = useSocket();
const { t } = useI18n();
const toast = useToast();

const monitors = ref<number>(0);
const added = ref<number>(0);
const notAdded = ref<number>(0);
const archived = ref<number>(0);

const expertMode = ref<boolean>(false);

const deleteDialog = ref<boolean>(false);
const selectedMonitor = ref<string>();

//loading spinners
const monitorSpinner = ref<boolean>(false);
const monitorInformation = ref<MonitorType[]>([]);

/** METHODS */
const showToast = (
  sev: ToastMessageOptions['severity'],
  sum: string,
  det: string
) => {
  toast.add({
    severity: sev,
    summary: sum,
    detail: det,
    life: 1500
  });
};

const setMaintenance = async (serialNumber: string, monitor: number) => {
  try {
    const response: AxiosResponse = await axios.get(
      `/api/monitors/one/${serialNumber}`
    );

    if (response.data.Maintenance) {
      await axios
        .patch(`/api/monitors/${serialNumber}/${monitor}`, {
          Maintenance: false
        })
        .then(() => {
          showToast(
            'success',
            'Success',
            t('admin.monitorConfiguration.deactivateMaintenance')
          );
        });
    } else {
      await axios
        .patch(`/api/monitors/${serialNumber}/${monitor}`, {
          Maintenance: true
        })
        .then(() => {
          showToast(
            'success',
            'Success',
            t('admin.monitorConfiguration.setMaintenance')
          );
        });
    }
  } catch (e) {
    showToast(
      'error',
      'Error',
      t('admin.monitorConfiguration.errors.cannotPutInMaintenance')
    );
    console.log(e);
  }
};

const deleteMonitor = async (serialNumber: string) => {
  try {
    await axios.delete(`/api/monitors/${serialNumber}`);

    monitorInformation.value = monitorInformation.value.filter(
      (monitor) => monitor.serialNumber !== serialNumber
    );

    deleteDialog.value = false;
    selectedMonitor.value = null;

    showToast(
      'success',
      'Success',
      t('admin.monitorConfiguration.deletedMonitor')
    );
  } catch (e) {
    showToast(
      'error',
      'Error',
      t('admin.monitorConfiguration.errors.cannotDeleteMonitor')
    );
    console.log(e);
  }
};

function enableExpertMode() {
  expertMode.value = !expertMode.value;

  if (expertMode.value) {
    showToast(
      'info',
      'Info',
      t('admin.monitorConfiguration.expertModeEnabled')
    );
  } else {
    showToast(
      'info',
      'Info',
      t('admin.monitorConfiguration.expertModeDisabled')
    );
  }
}

//* händelt die Monitor Informationen (aktiv / zugewiesen / nicht zugewiesen ...)
async function setupBannerInformation() {
  monitorSpinner.value = true;
  monitorInformation.value = [];
  const response: AxiosResponse = await axios.get('/api/monitors/all');
  if (response.data) {
    response.data.forEach((item: ItemType) => {
      monitorInformation.value.push({
        serialNumber: item.MonitorSerialNumber,
        related: item.Monitor,
        status: item.Active,
        maintenance: item?.Maintenance,
        knownSince: item?.createdAt,
        addedSince: item.relatedSince,
        confirmed: item?.Confirmed
      });
    });
  }

  //* reset
  monitors.value = 0;
  added.value = 0;
  notAdded.value = 0;
  archived.value = 0;

  //* anzahl der monitore
  monitors.value = monitorInformation.value.length;

  //* Monitor zuweisung indikator
  monitorInformation.value.forEach((monitor) => {
    if (monitor.related) {
      added.value++;
    } else {
      notAdded.value++;
    }

    //* archived status prüfen
    if (monitor.status) {
      archived.value++;
    }
  });
  monitorSpinner.value = false;
}

//* setzt die Monitor ID
const onSelect = async (selectedId: { name: string }, serialNumber: string) => {
  const currentTime = new Date();

  try {
    await axios
      .patch(
        `/api/monitors/${serialNumber}/${selectedId?.name.split('Monitor ')[1]}`,
        { relatedSince: currentTime }
      )
      .then(() => {
        showToast(
          'success',
          'Success',
          t('admin.monitorConfiguration.addedSuccessfully')
        );
      });
  } catch (e) {
    console.log(e);
    showToast(
      'error',
      'Error',
      t('admin.monitorConfiguration.errors.addedFailed')
    );
  }
};

const confirmMonitor = (serialNumber: string) => {
  axios
    .patch(`/api/monitors/register/${serialNumber}`, {
      confirmed: true
    })
    .then(() => {
      showToast(
        'success',
        'Success',
        t('admin.monitorConfiguration.confirmMonitor')
      );
    })
    .catch(() => {
      showToast(
        'error',
        'Error',
        t('admin.monitorConfiguration.errors.errorConfirmingMonitor')
      );
    });
};

const formatDate = (dateString: Date) => {
  return moment(dateString).format('DD.MM.YYYY HH:mm');
};

const openDeleteDialog = (serialNumber: string) => {
  selectedMonitor.value = serialNumber;
  deleteDialog.value = true;
};

const closeDeleteDialog = () => {
  selectedMonitor.value = null;
  deleteDialog.value = false;
};

/** HOOKS */
onMounted(async () => {
  socket?.value?.on('monitorUpdated', async () => {
    await setupBannerInformation();
  });

  await setupBannerInformation();
});
</script>

<template>
  <section class="content newScrollbar">
    <div class="monitor-picture">
      <div class="action-box">
        <div class="action-box-content">
          <div class="item-1">
            <h3>
              {{ t('admin.monitorConfiguration.monitorConfig') }}
            </h3>
            <p>
              {{ monitors }} {{ t('admin.monitorConfiguration.monitors') }} |
              {{ added }} {{ t('admin.monitorConfiguration.added') }} |
              {{ notAdded }} {{ t('admin.monitorConfiguration.notAdded') }} |
              {{ archived }}
              {{ t('admin.monitorConfiguration.archived') }}
            </p>
          </div>
        </div>
      </div>
      <div class="manage-buttons">
        <Button
          @click="enableExpertMode()"
          v-tooltip.top="{
            value: `${t('admin.monitorConfiguration.expert')}`,
            showDelay: 250,
            hideDelay: 0
          }"
          id="expert"
          icon="pi pi-slack"
          severity="secondary"
          rounded
          text
        />
      </div>
    </div>

    <div v-if="monitorSpinner" class="spinner">
      <ProgressSpinner :style="{ color: 'blue' }" />
    </div>

    <div class="no-monitor-found" v-if="!monitors">
      <h1>
        {{ t('admin.monitorConfiguration.notFound') }}
      </h1>
    </div>

    <div class="monitors-content" v-else>
      <h6 style="margin: 0">Monitore</h6>
      <Panel
        class="monitor-found"
        :class="{ 'frame-not-confirmed': !monitor.confirmed }"
        v-for="monitor in monitorInformation"
        :key="monitor.serialNumber"
        toggleable
        :collapsed="monitorInformation.length > 2"
      >
        <template #header>
          <div class="panel-header">
            <OverlayBadge :value="monitor.related">
              <Chip
                :label="monitor.serialNumber"
                icon="pi pi-desktop"
                :badge="monitor.related"
              />
            </OverlayBadge>
          </div>
        </template>
        <div class="panel-content">
          <div class="monitor-name">
            <h5>{{ t('admin.monitorConfiguration.serialNumber') }}</h5>
            <InputText
              id="monitorName"
              type="text"
              v-model="monitor.serialNumber"
              size="large"
              disabled
            />
          </div>
          <div class="details">
            <h5>{{ t('admin.monitorConfiguration.details') }}</h5>
            <ul>
              <li>
                {{ t('admin.monitorConfiguration.since') }}
                {{ formatDate(monitor.knownSince) }}
                {{ t('admin.monitorConfiguration.known') }}
              </li>
              <li v-if="monitor.addedSince">
                {{ t('admin.monitorConfiguration.since') }}
                {{ formatDate(monitor.addedSince) }}
                {{ t('admin.monitorConfiguration.added') }}
              </li>
              <li v-else>
                {{ t('admin.monitorConfiguration.notAdded') }}
              </li>
              <li>
                {{ t('admin.monitorConfiguration.serialNumber') }}:
                {{ monitor.serialNumber }}
              </li>
            </ul>
          </div>
          <div class="assigned-monitor">
            <h5>{{ t('admin.monitorConfiguration.monitor') }}</h5>
            <AutoCompleteComponent
              id="selectedId"
              :monitors="monitors"
              :related="monitor.related"
              v-if="!expertMode"
              :disabled="!monitor.confirmed"
              @monitor-selected="
                (selectedId) => onSelect(selectedId, monitor.serialNumber)
              "
            />
            <InputText
              v-else
              id="monitorID"
              type="number"
              v-model="monitor.related"
              size="large"
              disabled
            />
          </div>
          <div class="buttons">
            <router-link
              :to="`/monitor/${monitor.serialNumber}`"
              target="_blank"
            >
              <Button
                v-if="monitor.confirmed"
                v-tooltip="{
                  value: `${t('admin.monitorConfiguration.preview')}`,
                  showDelay: 250,
                  hideDelay: 0
                }"
                icon="pi pi-desktop"
                severity="info"
                :label="t('admin.monitorConfiguration.previewButton')"
              />
            </router-link>
            <Button
              v-tooltip="{
                value: `${t('admin.monitorConfiguration.delete')}`,
                showDelay: 750,
                hideDelay: 0
              }"
              @click="openDeleteDialog(monitor.serialNumber)"
              icon="pi pi-trash"
              severity="danger"
              :label="t('admin.monitorConfiguration.deleteButton')"
            />
            <Button
              v-tooltip="{
                value: `${t('admin.monitorConfiguration.maintenance')}`,
                showDelay: 750,
                hideDelay: 0
              }"
              @click="setMaintenance(monitor.serialNumber, monitor.related)"
              v-show="monitor.related && monitor.confirmed"
              id="wrench"
              icon="pi pi-wrench"
              severity="warn"
              :label="t('admin.monitorConfiguration.maintenanceButton')"
            />
            <Button
              v-if="monitor.confirmed === false || monitor.confirmed === null"
              v-tooltip="{
                value: t('admin.monitorConfiguration.confirmButtonTooltip'),
                showDelay: 750,
                hideDelay: 0
              }"
              @click="confirmMonitor(monitor.serialNumber)"
              icon="pi pi-check"
              :label="t('admin.monitorConfiguration.confirmButton')"
              severity="success"
            />
          </div>
        </div>
        <Dialog
          :visible="deleteDialog"
          :header="t('admin.monitorConfiguration.deletePopup')"
          :style="{ width: '25rem' }"
          :closable="false"
          pt:root:class="!border-0 !bg-transparent"
          pt:mask:class="backdrop-blur-sm"
        >
          <template #footer>
            <Button
              @click="closeDeleteDialog"
              :label="t('admin.monitorConfiguration.deletePopupCancel')"
              text
              severity="secondary"
              autofocus
            />
            <Button
              @click="deleteMonitor(selectedMonitor)"
              :label="t('admin.monitorConfiguration.delete')"
              autofocus
              severity="danger"
            />
          </template>
        </Dialog>
      </Panel>
    </div>
  </section>
</template>

<style scoped>
section {
  height: calc(100vh - 10.75rem);
}

#monitorName {
  width: 100%;
}

#selectedId,
#monitorID {
  width: 100%;
  height: auto;
}

#expert {
  color: var(--gray-color);
}

#expert:hover {
  background: var(--dark-gray-color);
  color: var(--danger-color);
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame-not-confirmed {
  animation: pulse 1s infinite alternate;
  background: transparent;
}

@keyframes pulse {
  0% {
    border: 3px solid var(--warning-color);
  }
  50% {
    border: 3px solid var(--danger-color);
  }
  100% {
    border: 3px solid var(--warning-color);
  }
}

.monitor-picture {
  position: relative;
  width: 100%;
  height: 21rem;
  border-radius: 1rem;
  background-image: url(../assets/pictures/banner-monitor-configuration.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.action-box-content {
  position: relative;
  margin-left: 2rem;
  width: calc(100% - 2rem);

  h3 {
    padding: 0;
    margin: 2rem 0 0;
  }

  .item-1 {
    display: grid;
    grid-template-rows: 0fr 0.25fr;
    width: 75%;
    gap: 0;
    margin: 0;
  }
}
.action-box {
  background-color: var(--gray-color);
  border-radius: 0.5rem;
  margin-top: 2rem;
  margin-left: 2rem;
  height: 10rem;
  width: 30rem;
}

.panel-header {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  margin-left: 2rem;
  margin-right: 2rem;
  gap: 2rem;
}

.panel-content {
  display: grid;
  grid-template-columns: 1fr 0.7fr 1fr 0.1fr;
  gap: 1.5rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.touchelement-found {
  grid-template-columns: 1fr 0.5fr 0.1fr;
}

.monitor-found,
.touchelement-found {
  padding: 0.5rem 0.5rem 0.5rem 0rem;
  display: grid;
  width: 100%;
  background: var(--gray-color);
  border-radius: 1rem;

  h5,
  h4 {
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
  }

  .monitor-name {
    display: flex;
    flex-direction: column;
  }

  .details {
    display: block;
  }

  .assigned-monitor {
    display: flex;
    flex-direction: column;
  }

  button {
    width: 8rem;
  }
}

.no-monitor-found {
  display: flex;
  justify-content: center;
}

.monitors-content,
.touch-element {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
}
.manage-buttons {
  position: absolute;
  border-radius: 1rem;
  bottom: 2.5%;
  right: 0.75%;
  zoom: 1.5;
}
</style>
