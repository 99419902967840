<script setup lang="ts">
import Card from 'primevue/card';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';

/** TYPES */

/** DATA */
const { t } = useI18n();

/** METHODS */

/** HOOKS */
</script>

<template>
  <div class="administration">
    <div class="content">
      <div class="picture">
        <div class="action-box">
          <div class="action-box-content">
            <h3>{{ t('admin.administration.title') }}</h3>
            <p>
              {{ t('admin.administration.description') }}
            </p>
          </div>
        </div>
      </div>
      <div class="cards">
        <Card class="card">
          <template #title>{{ t('admin.useradministration.title') }}</template>
          <template #content>
            <p class="m-0">
              {{ t('admin.useradministration.description') }}
            </p>
            <router-link to="/administration/users">
              <Button :label="t('admin.administration.open')" />
            </router-link>
          </template>
        </Card>

        <Card class="card">
          <template #title>{{
            t('admin.monitorConfiguration.monitorConfig')
          }}</template>
          <template #content>
            <p class="m-0">
              {{ t('admin.monitorConfiguration.description') }}
            </p>
            <router-link to="/administration/monitors">
              <Button :label="t('admin.administration.open')" />
            </router-link>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<style scoped>
h5 {
  margin-block-end: 0;
}

.action-box-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 2rem;
}

.action-box {
  display: flex;
  align-items: flex-start;
  background-color: #ebebeb;
  border-radius: 0.5rem;
  margin-top: 2rem;
  margin-left: 2rem;
  height: 50%;
  width: 30rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  margin-left: 2rem;
  margin-right: 2rem;
  gap: 3rem;
}
.picture {
  position: relative;
  display: flex;
  width: 100%;
  height: 20rem;
  border-radius: 1rem;
  background-image: url(../../assets/pictures/user-administration.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.card {
  width: 50%;
  height: 25%;
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
</style>
