<script setup lang="ts">
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';
import Toast, { type ToastMessageOptions } from 'primevue/toast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useI18n } from 'vue-i18n';
import { onMounted, ref } from 'vue';
import axios, { AxiosError } from 'axios';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Password from 'primevue/password';
import { userStore } from '../../stores/user';
import { Roles } from '../../interfaces/roles';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Paginator from 'primevue/paginator';
import ProgressSpinner from 'primevue/progressspinner';
import Chip from 'primevue/chip';
import Card from 'primevue/card';

/** TYPES */
type SelectedRole = {
  role: string;
};

type TableData = {
  Role: string;
  id: number;
  Name: string;
};

type UpdateUser = {
  password?: string;
  role?: string;
};

enum Mode {
  EDIT = 'edit',
  CREATE = 'create',
  WATCH = 'watch'
}

/** DATA */
const toast = useToast();
const { t } = useI18n();
const store = userStore();

const users = ref();
const count = ref();
const rows = ref(10);
const currentPage = ref(1);

const search = ref();

const deleteDialog = ref<boolean>(false);
const userDialog = ref<boolean>(false);
const mode = ref('');

const name = ref('');
const password = ref('');
const roles = ref([]);
const selectedRole = ref<SelectedRole>({ role: null });
const userId = ref<number>(null);

const nameDisabled = ref(false);
const selectRoleDisabled = ref(false);
const dialogHeader = ref('');

//loading spinners
const userSpinner = ref<boolean>(false);
const modifyUserSpinner = ref<boolean>(false);
const deleteUserSpinner = ref<boolean>(false);

const maxLength = 25;

/** METHODS */
const showToast = (
  sev: ToastMessageOptions['severity'],
  sum: string,
  det: string
) => {
  toast.add({
    severity: sev,
    summary: sum,
    detail: det,
    life: 3000
  });
};

const onPage = (event) => {
  currentPage.value = event.page + 1;
  rows.value = event.rows;
  const offset = (currentPage.value - 1) * rows.value;
  fetchUsers(offset);
};

const fetchUsers = async (offset: number) => {
  userSpinner.value = true;
  const res = await axios.get(`/api/user?offset=${offset}&limit=${rows.value}`);
  if (res.status === 200) {
    users.value = res.data.user;
    count.value = res.data.count;
    userSpinner.value = false;
  } else {
    showToast('error', 'Error', t('admin.dashboard.errors.searchError'));
  }
};

const fetchAvailableRoles = async () => {
  const res = await axios.get(`/api/user/${store.user.id}/roles`);
  if (res.status === 200) {
    roles.value = res.data;
  } else {
    showToast('error', 'Error', t('admin.dashboard.errors.roleError'));
  }
};

function generatePassword() {
  password.value = '';
  const length = 8;
  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    password.value += chars[randomIndex];
  }
}

const user = {
  createUser: async () => {
    if (name.value.trim() && password.value.trim() && selectedRole.value.role) {
      modifyUserSpinner.value = true;
      await axios
        .post('/api/user', {
          name: name.value,
          password: password.value,
          role: selectedRole.value.role
        })
        .then(async (res) => {
          if (res.status === 201) {
            modifyUserSpinner.value = false;
            await fetchUsers(0);
            showToast(
              'success',
              'Success',
              t('admin.dashboard.createUserSuccsesful')
            );
          } else {
            modifyUserSpinner.value = false;
            showToast(
              'error',
              'Error',
              t('admin.dashboard.errors.errorCreateUser')
            );
          }
        })
        .catch((e: AxiosError) => {
          if (e.status === 409) {
            modifyUserSpinner.value = false;
            showToast(
              'error',
              'Error',
              t('admin.useradministration.errors.userExist')
            );
          } else {
            modifyUserSpinner.value = false;
            showToast(
              'error',
              'Error',
              t('admin.dashboard.errors.errorCreateUser')
            );
          }
        });
    } else {
      showToast(
        'info',
        'Info',
        t('admin.useradministration.infos.emptyFields')
      );
    }
    modifyUserSpinner.value = false;
    name.value = '';
    selectedRole.value = { role: null };
    password.value = '';
    userDialog.value = false;
  },
  deleteUser: async () => {
    deleteUserSpinner.value = true;
    await axios
      .delete(`/api/user/${userId.value}`)
      .then(async (res) => {
        if (res.status === 200) {
          showToast(
            'info',
            t('admin.useradministration.infos.userDeleted'),
            t('admin.step.success.delete')
          );
          await fetchUsers(0);
        } else {
          showToast(
            'info',
            t('admin.useradministraion.errors.error'),
            t('admin.useradministration.errors.errorDeleteUser')
          );
        }
      })
      .catch((e) => {
        showToast(
          'error',
          'Error',
          t('admin.useradministration.errors.errorDeleteUser')
        );
        console.error(e);
      });
    deleteUserSpinner.value = false;
    deleteDialog.value = false;
    userId.value = null;
  },
  updateUser: async () => {
    if (password.value.trim() && selectedRole.value.role) {
      modifyUserSpinner.value = true;
      const payload: UpdateUser = {};

      if (password.value) {
        payload.password = password.value;
      }

      if (selectedRole.value && !selectRoleDisabled) {
        payload.role = selectedRole.value.role;
      }

      await axios
        .patch(`/api/user/${userId.value}`, payload)
        .then(async (res) => {
          if (res.status === 200) {
            showToast(
              'info',
              t('admin.useradministration.infos.editUser'),
              t('admin.useradministration.infos.editUserSuccessful')
            );
            await fetchUsers(0);
          }
        })
        .catch((e) => {
          if (e.status === 403) {
            showToast(
              'error',
              t('admin.useradministration.infos.noPermisson'),
              t('admin.useradministration.errors.errorAuthorization')
            );
          } else {
            showToast(
              'error',
              'Error',
              t('admin.useradministration.errors.errorEditUser')
            );
          }
          console.error(e.status);
        });
    } else {
      showToast(
        'info',
        'Info',
        t('admin.useradministration.infos.emptyFields')
      );
    }
    name.value = '';
    selectedRole.value = { role: null };
    password.value = '';
    modifyUserSpinner.value = false;
    userDialog.value = false;
  },
  searchUser: async () => {
    await axios
      .get(`/api/user`, {
        params: {
          offset: (currentPage.value - 1) * rows.value,
          limit: rows.value,
          search: search.value
        }
      })
      .then((res) => {
        users.value.splice(0, users.value.length);
        if (res.data.count) {
          users.value = res.data.user;
        } else {
          users.value.push(...res.data);
        }
      })
      .catch((e) => {
        if (e) {
          console.log(e);
          showToast('error', 'Error', t('admin.dashboard.errors.searchError'));
        }
      });
  }
};

const openUserDialog = (m: Mode, d?: TableData) => {
  if (m === Mode.CREATE) {
    mode.value = m;
    userDialog.value = true;
    dialogHeader.value = t('admin.monitorConfiguration.createUser');
  } else {
    mode.value = m;
    userDialog.value = true;
    nameDisabled.value = true;
    name.value = d.Name;
    userId.value = d.id;
    selectedRole.value = { role: d.Role };
    dialogHeader.value = t('admin.monitorConfiguration.userEdit');
  }
};

const closeUserDialog = async () => {
  name.value = '';
  selectedRole.value = { role: null };
  password.value = '';
  mode.value = null;
  nameDisabled.value = false;
  selectRoleDisabled.value = false;
  userDialog.value = false;
  userId.value = null;
  await fetchAvailableRoles();
};

const openDeleteDialog = (id: number) => {
  deleteDialog.value = true;
  userId.value = id;
};

const closeDeleteDialog = () => {
  deleteDialog.value = false;
};

/** HOOKS */
onMounted(async () => {
  await store.getUser();
  await fetchUsers(0);
  await fetchAvailableRoles();
});
</script>

<template>
  <div class="administration">
    <div class="content">
      <Toast></Toast>
      <div class="picture">
        <div class="action-box">
          <div class="action-box-content">
            <h3>{{ t('admin.useradministration.title') }}</h3>
            <p>
              {{ t('admin.useradministration.registeredUsers') }} {{ count }}
            </p>
          </div>
        </div>
      </div>
      <Card>
        <template #content>
          <div class="users">
            <div class="user-head">
              <div class="user-description">
                <h5>{{ t('admin.useradministration.user') }}</h5>
                <br />
              </div>
              <div class="new-user">
                <Button
                  type="button"
                  class="p-krum-primary"
                  @click="openUserDialog(Mode.CREATE)"
                  ><span class="pi pi-plus"></span
                  >{{ t('admin.useradministration.addUser') }}</Button
                >
              </div>
            </div>
            <div class="table">
              <div :class="t('admin.dashboard.search')">
                <IconField>
                  <InputIcon class="pi pi-search" />
                  <InputText
                    @keyup="user.searchUser"
                    class="search-input"
                    v-model="search"
                    :placeholder="t('admin.dashboard.search')"
                  />
                </IconField>
              </div>
              <DataTable
                class="data-table"
                :value="users"
                stripedRows
                tableStyle="width: 100%"
                style="border-radius: 1rem"
                :rows="rows"
                :first="(currentPage - 1) * rows"
                @page="onPage"
              >
                <Column
                  field="Name"
                  :header="t('admin.dashboard.name')"
                  style="width: 70%"
                >
                  <template #body="slotProps">
                    <Chip :label="slotProps.data.Name" icon="pi pi-user" />
                  </template>
                </Column>
                <Column
                  field="lastLogin"
                  :header="t('admin.useradministration.lastLogin')"
                  style="width: 10%"
                >
                </Column>
                <Column
                  field="Role"
                  :header="t('admin.useradministration.role')"
                  style="width: 10%"
                >
                </Column>
                <Column
                  field="actions"
                  :header="t('admin.useradministration.actions')"
                  style="width: 10%"
                >
                  <template #body="slotProps">
                    <Button
                      v-if="
                        (store.user.role === Roles.ADMIN &&
                          slotProps.data.Role === Roles.SNACK_ADMIN) ||
                        store.user.role === Roles.SERVICE_ADMIN
                      "
                      icon="pi pi-pen-to-square"
                      severity="secondary"
                      rounded
                      text
                      @click="openUserDialog(Mode.EDIT, slotProps.data)"
                    />
                    <Button
                      v-if="store.user.role === Roles.SERVICE_ADMIN"
                      @click="openDeleteDialog(slotProps.data.id)"
                      icon="pi pi-trash"
                      severity="danger"
                      rounded
                      text
                    />
                  </template>
                </Column>
              </DataTable>
              <div v-if="userSpinner" class="spinner">
                <ProgressSpinner :style="{ color: 'blue' }" />
              </div>
              <Paginator
                @page="onPage"
                :paginator="true"
                :totalRecords="count"
                :rows="rows"
              >
              </Paginator>
            </div>
          </div>
        </template>
      </Card>
      <Dialog
        :header="dialogHeader"
        :visible="userDialog"
        :style="{ width: '25rem' }"
        :closable="false"
        pt:root:class="!border-0 !bg-transparent"
        pt:mask:class="backdrop-blur-sm"
      >
        <div class="new-user-content">
          <InputText
            type="text"
            v-model="name"
            :placeholder="t('admin.dashboard.name')"
            :disabled="nameDisabled"
            :maxlength="maxLength"
          />
          <Password
            v-if="mode === Mode.CREATE || mode === Mode.EDIT"
            v-model="password"
            toggleMask
            :placeholder="t('login.password')"
          />
          <Select
            v-if="
              mode === Mode.CREATE ||
              (mode === Mode.EDIT && store.user.role === Roles.SERVICE_ADMIN) ||
              mode === Mode.WATCH
            "
            v-model="selectedRole"
            :options="roles"
            optionLabel="role"
            :placeholder="t('admin.monitorConfiguration.selectRole')"
            class="w-full md:w-56"
            :disabled="selectRoleDisabled"
          />
          <div v-if="modifyUserSpinner" class="spinner">
            <ProgressSpinner :style="{ color: 'blue' }" />
          </div>
        </div>
        <template #footer>
          <Button
            v-if="mode === Mode.CREATE"
            @click="generatePassword"
            :label="t('admin.monitorConfiguration.generatePassword')"
            text
            severity="secondary"
            autofocus
          />
          <Button
            @click="closeUserDialog"
            :label="t('admin.monitorConfiguration.deletePopupCancel')"
            text
            severity="secondary"
            autofocus
          />
          <Button
            v-if="mode === Mode.CREATE"
            @click="user.createUser"
            :label="t('admin.monitorConfiguration.createUser')"
            autofocus
          />
          <Button
            v-if="mode === Mode.EDIT"
            @click="user.updateUser"
            :label="t('admin.monitorConfiguration.deletePopupSave')"
            autofocus
          />
        </template>
      </Dialog>
      <Dialog
        :visible="deleteDialog"
        :header="t('admin.monitorConfiguration.userDeletePopup')"
        :style="{ width: '25rem', overflow: 'hidden' }"
        :closable="false"
        pt:root:class="!border-0 !bg-transparent"
        pt:mask:class="backdrop-blur-sm"
      >
        <div v-if="deleteUserSpinner" class="spinner">
          <ProgressSpinner :style="{ color: 'blue' }" />
        </div>
        <template #footer>
          <Button
            @click="closeDeleteDialog"
            :label="t('admin.monitorConfiguration.deletePopupCancel')"
            text
            severity="secondary"
            autofocus
          />
          <Button
            @click="user.deleteUser"
            :label="t('admin.dashboard.deleteRecipe')"
            autofocus
            severity="danger"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<style scoped>
#save-button {
  background: #0e4ca8;
  border: 1px solid #0e4ca8;
}

.spinner {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.char-counter {
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);
  font-size: 12px;
  color: gray;
}

.new-user-content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.p-dialog-mask {
  backdrop-filter: blur(10px);
}
.p-inputtext:enabled:focus {
  border-color: #0e4ca8;
}
.search {
  margin-bottom: 0.5rem;
}
.search-input {
  border-radius: 0px;
  width: 100%;
}
h5 {
  margin-block-end: 0;
}
.admin {
  height: 100%;
}
.table {
  width: 100%;
}
.table-user {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.new-user {
  display: flex;
  width: 55%;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
}
.user-description {
  display: flex;
  width: 45%;
  flex-direction: column;
}

.users {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.user-head {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #b4b4b4;
}

.action-box-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 2rem;
}

.action-box {
  display: flex;
  align-items: flex-start;
  background-color: #ebebeb;
  border-radius: 0.5rem;
  margin-top: 2rem;
  margin-left: 2rem;
  height: 50%;
  width: 30rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  margin-left: 2rem;
  margin-right: 2rem;
  gap: 3rem;
}
.inputs {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
.buttons {
  display: flex;
  justify-content: end;
  gap: 0.2rem;
  margin-top: 2rem;
}
.picture {
  position: relative;
  display: flex;
  width: 100%;
  height: 20rem;
  border-radius: 1rem;
  background-image: url(../../assets/pictures/user-administration.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.back {
  padding-left: 2rem;
  padding-top: 1rem;
}
</style>
