import { createI18n } from 'vue-i18n';
import de from './locales/de';
import en from './locales/en';

const messages = { de, en };

export default createI18n({
  legacy: false,
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages
});
