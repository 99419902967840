import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import { atleastRequiredRank, isAuthenticated } from './router-guard';
import NotFound from '../views/NotFound.vue';
import MonitorView from '../views/MonitorView.vue';
import StepView from '@/views/StepView.vue';
import RecipeView from '@/views/RecipeView.vue';
import OverView from '../views/OverView.vue';
import MonitorConfiguration from '@/views/MonitorConfiguration.vue';
import UserAdministration from '../views/administration/UserAdministration.vue';
import AdministrationOverview from '@/views/administration/AdministrationOverview.vue';
import MonitorAdministration from '@/views/administration/MonitorAdministration.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:catchAll(.*)',
      redirect: { name: 'notfound' }
    },
    {
      meta: { requiresAuth: true, disabled: true },
      path: '/',
      name: 'home',
      component: OverView
    },
    {
      meta: { requiresAuth: true },
      path: '/recipe',
      name: 'recipe',
      redirect: { name: 'myRecipe' },
      children: [
        {
          meta: {
            requiresAuth: true,
            disabled: true
          },
          path: ':recipeId',
          name: 'myRecipe',
          component: RecipeView
        },
        {
          meta: {
            requiresAuth: true,
            disabled: true
          },
          path: ':recipeId/step',
          name: 'step',
          component: StepView
        },
        {
          meta: {
            requiresAuth: true,
            disabled: true
          },
          path: ':recipeId/step/:stepId',
          name: 'editStep',
          component: StepView
        }
      ]
    },
    {
      meta: {
        requiresAuth: true,
        requiresViewCheck: 'admin'
      },
      path: '/administration',
      name: 'administration',
      children: [
        {
          meta: { disabled: true },
          path: '',
          name: 'adminOverview',
          component: AdministrationOverview
        },
        {
          meta: { disabled: true },
          path: 'users',
          name: 'userAdministration',
          component: UserAdministration
        },
        {
          meta: { disabled: true },
          path: 'monitors',
          name: 'monitorAdministration',
          component: MonitorAdministration
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/404',
      name: 'notfound',
      component: NotFound
    },
    {
      path: '/monitor',
      name: 'moni',
      redirect: { name: 'monitorConfig' },
      children: [
        {
          meta: {
            requiresAuth: true,
            disabled: true
          },
          path: 'configuration',
          name: 'monitorConfig',
          component: MonitorConfiguration
        },
        {
          path: ':serialNumber',
          name: 'monitor',
          component: MonitorView
        }
      ]
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  const authenticated = await isAuthenticated();
  const requiredRole = to.meta.requiresViewCheck as string | undefined;
  const allowed = requiredRole ? await atleastRequiredRank(requiredRole) : true;
  if (to.meta.requiresAuth && !authenticated) {
    next({ name: 'login' });
  } else if (to.meta.requiresViewCheck && !allowed) {
    next({ name: 'home' });
  } else {
    next();
  }
});
export default router;
