<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import Breadcrumb from 'primevue/breadcrumb';
import Navbar from './components/NavbarComponent.vue';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const { t } = useI18n();

const home = ref({
  icon: faHouse,
  path: '/'
});

const excludedRoutes: string[] = ['login', 'monitor'];

const isExcludedRoute = computed(() => {
  return excludedRoutes.includes(route.name as string);
});

const routes = computed(() => {
  return route.matched.map((r) => ({
    name: t(`admin.breadcrumps.${String(r.name)}`),
    path: r.meta.disabled ? null : r.path
  }));
});
</script>

<template>
  <div class="full-app">
    <Navbar v-if="!isExcludedRoute"></Navbar>
    <Breadcrumb
      v-if="!isExcludedRoute"
      :home="home"
      :model="routes"
      class="breadcrumbs"
    >
      <template #item="{ item, props }">
        <router-link
          v-if="item.path"
          v-slot="{ href, navigate }"
          :to="item.path"
          custom
        >
          <a :href="href" v-bind="props.action" @click="navigate">
            <font-awesome-icon
              v-if="item.icon"
              :icon="item.icon"
            ></font-awesome-icon>
            <span class="span-one">{{ item.name }}</span>
          </a>
        </router-link>
        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
          <span class="span-two">{{ item.name }}</span>
        </a>
      </template>
      <template #separator> / </template>
    </Breadcrumb>
    <RouterView />
  </div>
</template>

<style>
.breadcrumbs {
  padding-left: 2rem;
  font-size: large;
  font-weight: 500;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
}

#krumbein-breadcrumps {
  background-color: rgb(247, 247, 247);
}

footer {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.full-app {
  position: relative;
  min-height: 100vh; /* Mindestens die volle Höhe des Viewports */
  display: flex;
  flex-direction: column; /* Um Footer nach unten zu schieben */
}

#app {
  min-height: 100vh;
}
</style>
