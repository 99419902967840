export default {
  toastCallback: {
    success: 'Successfully',
    warn: 'Warning',
    danger: 'Error',
    info: 'Info'
  },
  login: {
    signIn: 'Sign In',
    login: 'Login',
    username: 'Username',
    password: 'Password',
    errors: {
      emptyInputs: 'Fields should not be empty',
      incorrect: 'Incorrect username or password'
    }
  },
  admin: {
    modalPassword: {
      changePassword: 'Change Password',
      newPassword: 'New Password',
      repeatPassword: 'Repeat Password',
      errors: {
        notMatched: 'Passwords do not match',
        empty: 'The passwords fields are empty'
      }
    },
    dashboard: {
      name: 'name',
      machineName: 'Machine name',
      recipeName: 'Recipe-Name',
      recipeProgramm: 'Recipe-Program',
      recipeSubname: 'Recipe-Caption',
      newRecipe: 'New Recipe',
      recipeOverview: 'Recipe overview',
      deleteRecipe: 'Delete',
      recipe: 'Recipe',
      status: 'Status',
      recipeProvider: 'Recipe Provider',
      action: 'Action',
      upload: 'Upload',
      download: 'Download',
      changePicture: 'Change',
      manageUsers: 'Administrate',
      pictureBib: 'Picture library',
      choose: 'Choose',
      savedRecipe: 'Saved recipe!',
      changeMachineName: 'Change machine name',
      changedRecipeOrder: 'changed recipe order!',
      cannotChangeRecipeOrder: 'Recipe order could not be changed!',
      maxStepsReached: 'Maximum number of 5 steps achieved with this monitor',
      sortLetters: 'Letters',
      sortDate: 'Date',
      inactive: 'Inactive',
      active: 'Active',
      search: 'Search',
      uploadRecipe: 'Upload Recipe',
      createUserSuccsesful: 'User was created successfully',
      programmSelect: 'Select a Program',
      monitor: 'Monitor',
      successprogramupdate: 'Program updated successfully',
      noProgram: 'No Program assigned!',
      fileUpload: 'Browse File',
      oneFile: 'Only one file can be selected',
      selectAll: 'Select all',
      createRecipe: 'Create',
      errors: {
        newRecipe: 'Recipe title must be given!',
        searchError: 'Error while searching',
        roleError: 'Roles could not be loaded',
        noRecipe: 'No recipe is choosen',
        deleteError: 'Error deleting recipe',
        errorRecipe: 'Recipe could not load',
        errorCreateUser: 'Error creating user',
        errorSaveOrder: 'Error when saving the recipe sequence:',
        errorLoadRecipe: 'Recipe/s could not be loaded'
      },
      tooltips: {
        edit: 'Edit Recipe',
        delete: 'Delete Recipe',
        switch: 'Turn Recipe on/off'
      }
    },
    monitorConfiguration: {
      monitorConfig: 'Monitor configuration',
      description: 'Monitore Verwalten',
      monitors: 'Monitors',
      notAdded: 'Not added',
      archived: 'In archive',
      notFound: 'Unfortunately no monitor were found.',
      serialNumber: 'Serial number',
      details: 'Details',
      since: 'Since',
      known: 'Known',
      added: 'Added',
      monitor: 'Assignment',
      chooseMonitor: 'Choose a monitor',
      expertModeEnabled: 'The expert mode were activated!',
      expertModeDisabled: 'The expert mode were deactivated!',
      expert: 'Expert mode',
      maintenance: 'Monitor maintenance',
      delete: 'Delete monitor',
      deletedMonitor: 'Successfully deleted monitor!',
      confirmMonitor: 'Successfully confirmed monitor!',
      setMaintenance: 'Successfully set monitor in maintenance mode',
      deactivateMaintenance: 'Successfully set monitor in production mode',
      addedSuccessfully: 'Monitor assigned successfully!',
      preview: 'Open preview',
      previewButton: 'Preview',
      deleteButton: 'Delete',
      maintenanceButton: 'Maintenance',
      deletePopup: 'Really delete monitor?',
      deletePopupSave: 'Save',
      deletePopupCancel: 'Cancel',
      confirmButton: 'Confirm',
      confirmButtonTooltip: 'Confirm monitor',
      userDeletePopup: 'Really delete user?',
      userDelete: 'Delete user',
      userEdit: 'Edit User',
      createUser: 'Create',
      selectRole: 'Select a Role',
      generatePassword: 'Generate Password',
      latestFilter: 'Latest',
      oldestFilter: 'Oldest',
      setRecipeActive: 'Recipe set active!',
      setRecipeInactive: 'Recipe set inactive!',
      recipeUploadSuccessfull: 'Recipe successfully uploaded!',
      successDelete: 'Recipe successfully deleted',
      recipeDeletePopup: 'Really delete the recipe?',
      errors: {
        errorUpdateProgram: 'Error when updating the program',
        errorEditRecipeName: 'Error when editing the recipe name',
        errorConfirmingMonitor: 'Error when confirming the monitor',
        errorPasswordUpdate: 'Password could not be updated',
        errorRecipeStatus: 'Could not change recipe status!',
        recipeUploadError: 'Recipe can not be uploaded',
        addedFailed: 'Monitor allocation failed!',
        cannotDeleteMonitor: 'Cannot delete monitor!',
        cannotPutInMaintenance: 'Cannot change maintenance mode!'
      }
    },
    step: {
      createStep: 'Create step',
      addStep: 'Add Step',
      editStep: 'Edit Step',
      deleteStep: 'Delete step',
      step: 'Step',
      title: 'Title',
      instruction: 'Instruction',
      saveStep: 'Save step',
      draganddrop: 'Drag and drop files to here to upload.',
      importantNote: 'Important Note',
      deleteStepPopup: 'Really delete monitor?',
      errors: {
        createStep: 'Error while creating the step',
        emptyFields: 'Fields are not filled',
        editStep: 'Error while edit the step',
        monitorOptions: 'No option found for Monitor:'
      },
      success: {
        created: 'Step successfully created',
        updated: 'Step successfully updated',
        delete: 'Step successfully deleted'
      }
    },
    administration: {
      title: 'Administration',
      description: 'This is the administration overview.',
      open: 'Open'
    },
    useradministration: {
      title: 'User Administration',
      description: 'Administrate users',
      registeredUsers: 'Registered users',
      user: 'User',
      role: 'Role',
      addUser: 'Add User',
      lastLogin: 'Last Login',
      actions: 'Actions',
      editRecipeNameSuccessful: 'Recipe name successfully changed',
      infos: {
        emptyFields: 'Fields not filled',
        editUserSuccessful: 'User processed successfully',
        userDeleted: 'User deleted',
        editUser: 'User Edited',
        noPermission: 'No Permission'
      },
      errors: {
        errorEditUser: 'User could not be edited',
        errorDeleteUser: 'User could not be deleted',
        errorPermission: 'You have no permission',
        error: 'Something went wrong',
        userExist: 'User with this name already exists'
      }
    },
    navebarComponent: {
      language: 'Language',
      english: 'English',
      german: 'German',
      errorLogout: 'Failed to logout',
      tooltips: {
        chooseLanguage: 'Choose language',
        logout: 'Logout'
      }
    },
    sidebarComponent: {
      dashbaord: 'Dashboard',
      administraion: 'Administration',
      monitor: 'Monitor'
    },
    breadcrumps: {
      home: 'Home',
      recipe: 'Recipe',
      myRecipe: 'My Recipe',
      editStep: 'Edit Step',
      step: 'Add Step',
      moni: 'Monitor',
      monitorConfig: 'Monitor Configuration',
      administration: 'Administration',
      adminOverview: 'Admin Overview',
      userAdministration: 'User Administration',
      monitorAdministration: 'Monitor Administration'
    }
  }
};
