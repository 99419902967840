<script setup lang="ts">
import { useRoute } from 'vue-router';
import axios, { type AxiosResponse } from 'axios';
import { useSocket } from '@/assets/useSocket';
import { onMounted, ref } from 'vue';
import type { ToastMessageOptions } from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import type { Step } from '@/interfaces/step';
import ProgressSpinner from 'primevue/progressspinner';
import { type Recipe } from '@/interfaces/recipe';

/** TYPES */
type stepItem = {
  MonitorNumber: number;
  id: number;
  Title: string;
  Text: string;
  Picture: string;
  stepNumber?: number;
};

/** DATA */
const { socket } = useSocket();
const toast = useToast();
const route = useRoute();

const showSerialNumber = ref<boolean>(false);
const maintenance = ref<boolean>(false);
const monitor = ref<string>('Monitor 1');
const Moni = ref<number>();
const serialNumber = route.params.serialNumber;
const activeRecipe = ref<boolean>();

const stepResponse = ref();
const finishedSteps = ref<Step[]>([]);
const recipes = ref<Recipe>();

const spinner = ref<boolean>(false);

/** METHODS */
const showToast = (
  sev: ToastMessageOptions['severity'],
  sum: string,
  det: string
) => {
  toast.add({
    severity: sev,
    summary: sum,
    detail: det,
    life: 1500
  });
};

async function handleBeforeSetup(response: any) {
  const { Monitor, Maintenance } = response.data;
  showSerialNumber.value = false;
  maintenance.value = false;

  Moni.value = Monitor;

  if (Maintenance === true) {
    showSerialNumber.value = true;
    maintenance.value = true;
  }
  if (Monitor) monitor.value = `Monitor ${Monitor}`;
  await getActiveRecipe();
}

async function getActiveRecipe() {
  spinner.value = true;
  try {
    const recipe = await axios.get(
      `/api/monitors/${serialNumber}/recipe/active`
    );
    if (recipe.data) {
      recipes.value = recipe.data;
      const res = await axios.get(
        `/api/monitors/${serialNumber}/recipe/${recipe.data.id}`
      );
      stepResponse.value = res.data;
      activeRecipe.value = true;
    } else {
      activeRecipe.value = false;
      spinner.value = false;
    }

    if (stepResponse.value && stepResponse.value.steps) {
      finishedSteps.value = [];
      stepResponse.value.steps.forEach((item: stepItem, index: number) => {
        if (item.MonitorNumber === Moni.value) {
          finishedSteps.value.push({
            id: item.id,
            Title: item.Title,
            Text: item.Text,
            Picture: item.Picture,
            stepNumber: index + 1
          });
        }
      });
      spinner.value = false;
    }
  } catch (e) {
    showToast('error', 'Error', 'Recipe could not load');
    spinner.value = false;
  }
}

/** HOOKS */
onMounted(async () => {
  spinner.value = true;
  await axios
    .post(`/api/monitors/create/${serialNumber}`)
    .then((res) => {
      if (res.status === 201) {
        showSerialNumber.value = true;
        spinner.value = false;
      }
    })
    .catch(async (e) => {
      if (e.response.status === 409) {
        const response: AxiosResponse = await axios.get(
          `/api/monitors/one/${serialNumber}`
        );
        await handleBeforeSetup(response);
        spinner.value = false;
      } else {
        showToast('error', 'Fehler', 'fehler');
      }
    });

  socket?.value?.on('recipeUpdated', (r: Recipe) => {
    if (r.TargetQuantity || r.CurrentQuantity) {
      recipes.value = r;
    } else {
      getActiveRecipe();
      finishedSteps.value = [];
      stepResponse.value = null;
    }
  });

  socket?.value?.on('monitorUpdated', async (data: any) => {
    const response: AxiosResponse = await axios.get(
      `/api/monitors/one/${data.serialNumber}`
    );
    if (data.serialNumber === serialNumber) await handleBeforeSetup(response);
  });
});
</script>

<template>
  <section class="full-content">
    <div v-if="spinner" class="spinner">
      <ProgressSpinner :style="{ color: 'blue' }" />
    </div>
    <div v-else>
      <div v-if="showSerialNumber" class="serialnumber">
        <div class="frame" :class="{ 'frame-maintenance': maintenance }">
          <h1>{{ serialNumber }}</h1>
          <h6 v-show="maintenance">{{ monitor }}</h6>
        </div>
      </div>

      <div v-else-if="finishedSteps[0]" class="content-container">
        <div class="fixed-item">
          <div class="container">
            <div class="text">
              <div
                v-if="!recipes.TargetQuantity && !recipes.CurrentQuantity"
                class="title"
              >
                <h2 style="text-decoration: underline">{{ recipes.Title }}</h2>
              </div>
              <div
                v-if="recipes.TargetQuantity || recipes.CurrentQuantity"
                class="title-one"
              >
                <div class="box-container" style="left: 1rem">
                  <div
                    class="blue-box"
                    style="background-color: var(--dark-blue-color)"
                  >
                    <h3 style="font-weight: lighter">Soll</h3>
                    <h3>{{ recipes.CurrentQuantity }}</h3>
                  </div>
                </div>
                <h2 style="text-decoration: underline">{{ recipes.Title }}</h2>
                <div class="box-container">
                  <div
                    class="blue-box"
                    style="background-color: var(--dark-blue-color)"
                  >
                    <h3 style="font-weight: lighter">Ist</h3>
                    <h3>{{ recipes.TargetQuantity }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="finishedSteps.length > 1" class="masonry-container">
          <div
            class="masonry-item"
            v-for="(step, index) in finishedSteps"
            :key="step.id"
          >
            <div class="step-bubble">
              <h5>{{ step.stepNumber }}</h5>
            </div>
            <div class="image-container">
              <img :src="step.Picture" :alt="step.Title" />
            </div>
            <div class="content">
              <h3 class="step-title">{{ step.Title }}</h3>
              <div v-html="step.Text" class="formatted-text"></div>
            </div>
          </div>
        </div>

        <div v-if="finishedSteps.length === 1" class="masonry-container-one">
          <div
            class="masonry-item"
            v-for="(step, index) in finishedSteps"
            :key="step.id"
          >
            <div class="step-bubble">
              <h5>{{ step.stepNumber }}</h5>
            </div>
            <div class="image-container">
              <img :src="step.Picture" :alt="step.Title" />
            </div>
            <div class="content">
              <h3 class="step-title">{{ step.Title }}</h3>
              <div v-html="step.Text" class="formatted-text"></div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="idle-layout frame">
        <div v-if="!activeRecipe" class="no-active-recipe">
          <img
            src="../assets/pictures/monitor-screensaver.jpg"
            alt="logo"
            class="screen-saver"
          />
        </div>
        <div v-else>
          <h1>
            Leider wurde
            <span style="color: var(--warning-color)">kein Schritt</span> für
            diesen Monitor hinterlegt!
          </h1>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.full-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #e9e9e9;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.serialnumber {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .frame {
    min-width: 25%;
    text-align: center;
    border: 1px solid var(--gray-color);
    border-radius: 1rem;
    padding: 1rem;
    background: var(--gray-color);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }

  .frame-maintenance {
    animation: pulse 1.5s infinite alternate;
    background: transparent;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 15vh var(--warning-color);
    background: var(--gray-color);
  }
  50% {
    box-shadow: 0 0 22vh var(--danger-color);
    background: var(--gray-color);
  }
  100% {
    box-shadow: 0 0 15vh var(--warning-color);
    background: var(--gray-color);
  }
}

.target-current {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 1;
}

.blue-box {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 8px 15px;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  height: 7rem;
  width: 7rem;
  gap: 0.5rem;

  p,
  h3 {
    margin: 0;
    padding: 0;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fixed-item {
  height: 15vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .box-container {
    padding-right: 10px;
  }

  .text {
    width: 100%;
    h2,
    h4 {
      margin: 0;
      padding: 0;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .title-one {
      font-size: 18px;
      font-weight: bold;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .subtitle {
      font-size: 14px;
    }

    .line {
      border-top: 1px solid var(--dark-gray-color);
      margin-top: 10px;
    }
  }
}

.masonry-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding: 15px;
  overflow-y: auto;
  height: calc(100vh - 15vh);
}

.masonry-container-one {
  display: grid;
  grid-template-columns: 1;
  gap: 15px;
  padding: 15px;
  overflow-y: auto;
  height: calc(100vh - 15vh);
}

.masonry-item {
  display: flex;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  align-items: stretch;
  height: 100%;
}

.masonryitem:only-child {
  grid-template-columns: 1 !important;
}

.step-bubble {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: var(--dark-blue-color);
  color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.image-container {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
  background-color: #f9f9f9;
}

.image-container img {
  max-width: 100%;
  height: 100%;

  object-fit: contain;
  display: block;
  margin: auto;
}

.content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}

.step-title {
  font-size: 2vw;
  margin: 0 0 10px;
  color: var(--black-color);
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}

.formatted-text {
  flex: 1;
  color: #555;
  font-size: 1.1em;
  line-height: 1.6;
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .masonry-container {
    grid-template-columns: 1fr;
  }

  .image-container {
    width: 100%;
    padding: 15px;
  }

  .masonry-item {
    flex-direction: column;
    height: auto;
  }

  .step-bubble {
    top: 15px;
    left: 15px;
  }
}

.idle-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
  overflow: hidden;
}

.no-active-recipe {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.screen-saver {
  max-width: 100%;
  height: auto;
  width: auto;
  height: auto;
}

.text-container {
  display: flex;
  flex-direction: column;

  h1,
  h6 {
    margin: 0;
    padding: 0;
  }
}

.logo-container {
  position: absolute;
  align-items: center;
  top: 93.5%;
  display: flex;
  flex-direction: column;

  h6 {
    padding: 0;
    margin: 0;
  }

  img {
    width: 7.5%;
  }
}
</style>
