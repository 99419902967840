<script setup lang="ts">
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Toast, { type ToastMessageOptions } from 'primevue/toast';
import SidebarComponent from './SidebarComponent.vue';
import 'primeicons/primeicons.css';
import { useI18n } from 'vue-i18n';
import Menu from 'primevue/menu';
import { ref } from 'vue';
import Button from 'primevue/button';
import { Locales } from '../interfaces/language';
import deFlag from '../assets/flags/de.png';
import enFlag from '../assets/flags/en.png';

/** DATA */
const router = useRouter();
const toast = useToast();
const menu = ref();

const { locale, t } = useI18n();

const languages = ref([
  {
    label: 'admin.navebarComponent.language',
    items: [
      {
        flag: enFlag,
        label: 'admin.navebarComponent.english',
        command: () => {
          locale.value = Locales.ENGLISH;
        }
      },
      {
        flag: deFlag,
        label: 'admin.navebarComponent.german',
        command: () => {
          locale.value = Locales.GERMAN;
        }
      }
    ]
  }
]);

/** METHODS */
const showToast = (
  sev: ToastMessageOptions['severity'],
  sum: string,
  det: string
) => {
  toast.add({
    severity: sev,
    summary: sum,
    detail: det,
    life: 3000
  });
};

const logout = async () => {
  await axios
    .get('/api/auth/logout')
    .then((res) => {
      if (res.status === 200) {
        router.push({ name: 'login' });
      }
    })
    .catch((e) => {
      if (e) {
        showToast('error', 'Error', t('admin.navebarComponent.errorLogout'));
      }
    });
};

const toggle = (event) => {
  menu.value.toggle(event);
};
</script>

<template>
  <div class="navbar">
    <Toast></Toast>
    <div class="navbar-items">
      <div class="logo">
        <SidebarComponent />
        <router-link to="/">
          <img
            class="logo-svg"
            src="../assets/logo-white.png"
            alt="Logo"
            width="300px"
          />
        </router-link>
      </div>
      <div class="navbar-actions">
        <div>
          <Button
            class="hov"
            @click="toggle"
            icon="pi pi-globe"
            rounded
            text
            v-tooltip.bottom="
              t('admin.navebarComponent.tooltips.chooseLanguage')
            "
            style="color: #ffff"
          />
          <Menu ref="menu" id="overlay_menu" :model="languages" :popup="true">
            <template #submenulabel="{ item }"> {{ t(item.label) }} </template>
            <template #item="{ item }">
              <div class="p-menu-item-link">
                <img :src="item.flag" :alt="item.label" />
                <span class="p-menu-item-label">{{ t(item.label) }}</span>
              </div>
            </template>
          </Menu>
        </div>
        <div>
          <Button
            class="hov"
            icon="pi pi-power-off"
            rounded
            text
            style="color: #ffff"
            v-tooltip.left="t('admin.navebarComponent.tooltips.logout')"
            @click="logout"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.navbar-actions {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  gap: 1rem;
}
.navbar {
  width: 100%;
  background-color: #0e4ca8;
  height: 4rem;
  display: flex;
  align-items: center;
}
.navbar-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#icons {
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
}
.logo {
  display: flex;
  justify-content: flex-start;
  margin-left: 1rem;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 600px) {
  .logo-svg {
    display: none;
  }
}
</style>
