<script setup lang="ts">
import Dialog from 'primevue/dialog';
import Password from 'primevue/password';
import Button from 'primevue/button';
import { onBeforeMount, onMounted, ref } from 'vue';
import axios, { type AxiosResponse } from 'axios';
import { useToast } from 'primevue/usetoast';
import Toast, { type ToastMessageOptions } from 'primevue/toast';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import { userStore } from '@/stores/user';
import { useRouter } from 'vue-router';
import type { Recipe } from '@/interfaces/recipe';
import { useI18n } from 'vue-i18n';
import Badge from 'primevue/badge';
import { useSocket } from '@/assets/useSocket';
import FileUpload, { type FileUploadSelectEvent } from 'primevue/fileupload';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Paginator from 'primevue/paginator';
import ProgressSpinner from 'primevue/progressspinner';
import Menu from 'primevue/menu';
import Select from 'primevue/select';
import Card from 'primevue/card';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFileCode } from '@fortawesome/free-solid-svg-icons';

/** TYPES */
type Filter = {
  operation: string;
  option: string;
};

/** DATA */
const { socket } = useSocket();
const toast = useToast();
const store = userStore();
const router = useRouter();
const { t } = useI18n();

// change password dialog
const changePasswordVisible = ref<boolean>(false);

// new recipe dialog
const newRecipeVisible = ref<boolean>(false);

// delete recipe dialog
const deleteDialog = ref<boolean>(false);

// upload file dialog
const uploadFileDialog = ref<boolean>(false);

const password = ref<string>('');
const repeatPassword = ref<string>('');
const recipeTitle = ref<string>('');
const recipes = ref<Recipe[]>([]);
const active = ref<boolean>();
const file = ref(null);

// for delete or edit recipe
const selectedRecipe = ref<Recipe>();
// choose the recipe via checkbox
const choosenRecipe = ref<Recipe[]>([]);
// select all recipes
const selectAll = ref<boolean>(false);

const filter = ref<Filter>({
  operation: '',
  option: ''
});
const search = ref<string>();
const count = ref();
const rows = ref(10);
const currentPage = ref(1);

// loading Spinners
const recipeSpinner = ref<boolean>(false);
const uploadSpinner = ref<boolean>(false);
const newRecipeLoading = ref<boolean>(false);
const downloadLoading = ref<boolean>(false);
const deleteRecipeLoading = ref<boolean>(false);

const fileButton = ref<boolean>(false);

const menu = ref();

const items = ref([
  {
    label: 'admin.dashboard.sortLetters',
    items: [
      {
        label: 'A',
        icon: 'pi pi-arrow-down',
        command: async () => {
          filter.value.operation = 'ASC';
          filter.value.option = 'Title';
          const offset = (currentPage.value - 1) * rows.value;
          await getRecipes(offset);
        }
      },
      {
        label: 'Z',
        icon: 'pi pi-arrow-up',
        command: async () => {
          filter.value.operation = 'DESC';
          filter.value.option = 'Title';
          const offset = (currentPage.value - 1) * rows.value;
          await getRecipes(offset);
        }
      }
    ]
  },
  {
    label: 'admin.dashboard.sortDate',
    items: [
      {
        label: 'admin.monitorConfiguration.latestFilter',
        icon: 'pi pi-arrow-down',
        command: async () => {
          filter.value.operation = 'DESC';
          filter.value.option = 'createdAt';
          const offset = (currentPage.value - 1) * rows.value;
          await getRecipes(offset);
        }
      },
      {
        label: 'admin.monitorConfiguration.oldestFilter',
        icon: 'pi pi-arrow-up',
        command: async () => {
          filter.value.operation = 'ASC';
          filter.value.option = 'createdAt';
          const offset = (currentPage.value - 1) * rows.value;
          await getRecipes(offset);
        }
      }
    ]
  }
]);

const selectedProgram = ref();
const programs = ref([]);

const checked = ref(false);

/** METHODS */
const toggle = (event) => {
  menu.value.toggle(event);
};

const showToast = (
  sev: ToastMessageOptions['severity'],
  sum: string,
  det: string
) => {
  toast.add({
    severity: sev,
    summary: sum,
    detail: det,
    life: 3000
  });
};

async function changeActiveRecipeState() {
  try {
    const recipe = await axios.get('/api/recipe/active');
    if (recipe.data) {
      await axios.patch(`/api/recipe/${recipe.data.id}/false`);
    }
  } catch (e) {
    console.log(e);
  }
}

async function changePassword() {
  if (password.value !== repeatPassword.value) {
    showToast('error', 'Error', t('admin.modalPassword.errors.notMatched'));
  } else {
    if (password.value.trim() && repeatPassword.value.trim()) {
      try {
        const res = await axios.patch('/api/user/password', {
          newPassword: password.value
        });
        changePasswordVisible.value = false;
        showToast('success', 'Success', `${res.data.message}`);
      } catch (e) {
        showToast(
          'error',
          'Error',
          t('admin.monitorConfiguration.errors.errorPasswordUpdate')
        );
      }
    } else {
      showToast('error', 'Error', t('admin.modalPassword.errors.empty'));
    }
  }
}

async function createRecipe() {
  newRecipeLoading.value = true;
  if (recipeTitle.value.trim() && selectedProgram.value) {
    await axios
      .post('/api/recipe', {
        title: recipeTitle.value,
        programId: selectedProgram.value ? selectedProgram.value.id : null
      })
      .then((res) => {
        if (res.status === 201) {
          const recipeId = res.data;
          newRecipeLoading.value = false;
          router.push({
            name: 'myRecipe',
            params: { recipeId: `${recipeId}` }
          });
        }
      })
      .catch(() => {
        showToast('error', 'Error', 'fehler beim erstellen');
      });
  } else {
    showToast('info', 'Info', 'Felder nicht gefüllt');
    newRecipeLoading.value = false;
  }
}

const onPage = (event: any) => {
  currentPage.value = event.page + 1;
  rows.value = event.rows;
  const offset = (currentPage.value - 1) * rows.value;
  getRecipes(offset);
};

async function getRecipes(offset: number) {
  recipeSpinner.value = true;

  if (filter) {
    await axios
      .get(
        `/api/recipe?offset=${offset}&limit=${rows.value}&operation=${filter.value.operation}&option=${filter.value.option}`
      )
      .then((res) => {
        if (res.status === 200) {
          recipes.value = res.data.recipes;
          count.value = res.data.count;
          recipeSpinner.value = false;
        }
      })
      .catch((e) => {
        recipeSpinner.value = false;
        showToast(
          'error',
          'Error',
          t('admin.dashboard.errors.errorLoadRecipe')
        );
      });
  } else {
    await axios
      .get(`/api/recipe?offset=${offset}&limit=${rows.value}`)
      .then((res) => {
        if (res.status === 200) {
          recipes.value = res.data.recipes;
          count.value = res.data.count;
          recipeSpinner.value = false;
        }
      })
      .catch((e) => {
        recipeSpinner.value = false;
        showToast(
          'error',
          'Error',
          t('admin.dashboard.errors.errorLoadRecipe')
        );
      });
  }
}

const searchRecipe = async () => {
  await axios
    .get(`/api/recipe`, {
      params: {
        offset: (currentPage.value - 1) * rows.value,
        limit: rows.value,
        search: search.value
      }
    })
    .then((res) => {
      recipes.value.splice(0, recipes.value.length);
      if (res.data.count) {
        recipes.value = res.data.recipes;
      } else {
        recipes.value.push(...res.data);
      }
    })
    .catch((e) => {
      if (e) {
        console.log(e);
        showToast('error', 'Error', t('admin.dashbaord.errors.searchError'));
      }
    });
};

async function setRecipeActive(d: Recipe) {
  await changeActiveRecipeState();
  try {
    if (d.Active) {
      await axios.patch(`/api/recipe/${d.id}/false`).then(() => {
        showToast(
          'success',
          'Success',
          t('admin.monitorConfiguration.setRecipeInactive')
        );
      });
    } else {
      await axios.patch(`/api/recipe/${d.id}/true`).then(() => {
        showToast(
          'success',
          'Success',
          t('admin.monitorConfiguration.setRecipeActive')
        );
      });
    }
    const offset = (currentPage.value - 1) * rows.value;
    await getRecipes(offset);
  } catch (e) {
    console.log(e);
    showToast(
      'error',
      'Error',
      t('admin.monitorConfiguration.errors.errorRecipeStatus')
    );
  }
}

const editRecipe = (d: Recipe) => {
  router.push({
    path: `/recipe/${d.id}`
  });
};

const deleteRecipe = () => {
  const recipeId = selectedRecipe.value.id;
  deleteRecipeLoading.value = true;
  axios
    .delete(`/api/recipe/${recipeId}`)
    .then(async (res) => {
      if (res.status === 200) {
        deleteRecipeLoading.value = false;
        deleteDialog.value = false;
        const offset = (currentPage.value - 1) * rows.value;
        await getRecipes(offset);
        showToast(
          'success',
          'Success',
          t('admin.monitorConfiguration.successDelete')
        );
      }
    })
    .catch((e) => {
      if (e) {
        deleteRecipeLoading.value = false;
        deleteDialog.value = false;
        showToast('error', 'Error', t('admin.dashbaord.errors.deleteError'));
      }
    });
};

const downloadRecipe = () => {
  if (
    choosenRecipe.value.length > 0 ||
    (selectAll.value && recipes.value.length > 0)
  ) {
    downloadLoading.value = true;
    const ids: number[] = choosenRecipe.value.map((r) => r.id);
    axios({
      url: selectAll.value ? '/api/recipe/file' : `/api/recipe/file/${ids}`,
      method: 'GET',
      responseType: 'blob'
    })
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = url.split('/').pop();
        document.body.appendChild(a);
        a.click();

        a.remove();
        window.URL.revokeObjectURL(url);
        downloadLoading.value = false;
      })
      .catch((e) => console.error(e));
  } else {
    showToast('info', 'Info', t('admin.dashboard.errors.noRecipe'));
  }
};

function browseFile(event: FileUploadSelectEvent) {
  if (event.files.length > 1) {
    event.files.splice(0, 1);
  }
  if (!file.value) {
    file.value = event.files;
    fileButton.value = true;
  } else {
    showToast('info', 'Info', t('admin.dashboard.oneFile'));
  }
}

const uploadFile = async () => {
  if (file.value) {
    uploadSpinner.value = true;
    const formData = new FormData();

    formData.append('file', file.value[0]);

    await axios
      .post('/api/recipe/file', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(async (res) => {
        if (res.status === 201) {
          uploadSpinner.value = false;
          uploadFileDialog.value = false;
          await getRecipes(0);
          showToast(
            'success',
            'Success',
            t('admin.monitorConfiguration.recipeUploadSuccessfull')
          );
          fileButton.value = false;
          file.value = null;
        }
      })
      .catch((e) => {
        fileButton.value = false;
        uploadFileDialog.value = false;
        file.value = null;
        showToast(
          'error',
          'Error',
          t('admin.monitorConfiguration.errors.recipeUploadError')
        );
      });
  }
};

function formatSize(size) {
  if (size < 1024) {
    return size + ' Bytes';
  } else if (size < 1024 * 1024) {
    return (size / 1024).toFixed(2) + ' KB';
  } else if (size < 1024 * 1024 * 1024) {
    return (size / (1024 * 1024)).toFixed(2) + ' MB';
  } else {
    return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
  }
}

const clearFileZone = () => {
  file.value = null;
  fileButton.value = false;
};

const openDeleteDialog = (d: Recipe) => {
  selectedRecipe.value = d;
  deleteDialog.value = true;
};

const closeDeleteModal = () => {
  selectedRecipe.value = null;
  deleteDialog.value = false;
};

const openNewRecipeDialog = async () => {
  const res = await axios.get('/api/touch/programs');
  programs.value = res.data;
  newRecipeVisible.value = true;
};

const closeNewRecipeDialog = () => {
  selectedProgram.value = null;
  recipeTitle.value = '';
  newRecipeVisible.value = false;
};

const closeUploadfileDialog = () => {
  uploadFileDialog.value = false;
  file.value = null;
  fileButton.value = false;
};

const setChecked = () => {
  checked.value = true;
  choosenRecipe.value = [];
};

const excludeRecipe = async (id: number) => {
  selectAll.value = false;
  choosenRecipe.value = (await axios.get('/api/recipe/all')).data;
  choosenRecipe.value = choosenRecipe.value.filter((r) => r.id !== id);
};

/** HOOKS */
onBeforeMount(async () => {
  await store.getUser();
  const response: AxiosResponse = await axios.get('/api/user/password-changed');
  changePasswordVisible.value = response.data === false;
});

onMounted(async () => {
  await store.getUser();
  socket?.value?.on('recipeUpdated', () => {
    getRecipes(0);
  });

  await getRecipes(0);
});
</script>

<template>
  <div class="admin">
    <div class="content">
      <Toast></Toast>
      <div class="picture">
        <div class="action-box">
          <div class="action-box-content">
            <div class="wifi">
              <h4>Snackline</h4>
              <InputText disabled />
            </div>
            <Button
              class="new-recipe-button"
              severity="warn"
              type="button"
              :label="t('admin.dashboard.newRecipe')"
              @click="openNewRecipeDialog"
            />
          </div>
        </div>
      </div>
      <Card>
        <template #content>
          <div class="recipes">
            <div class="recipse-head">
              <div class="recipe-description">
                <h5>{{ t('admin.dashboard.recipeOverview') }}</h5>
                <br />
              </div>
              <div class="upload-download">
                <div class="download">
                  <Button
                    @click="downloadRecipe"
                    severity="secondary"
                    type="button"
                    :loading="downloadLoading"
                    :label="t('admin.dashboard.download')"
                  />
                </div>
                <Button
                  @click="uploadFileDialog = true"
                  type="button"
                  class="p-krum-primary"
                  :label="t('admin.dashboard.upload')"
                  ><span class="pi pi-upload"></span
                  >{{ t('admin.dashboard.upload') }}</Button
                >
              </div>
            </div>
            <div class="table">
              <div class="search">
                <div style="width: 95%">
                  <IconField class="icon-field">
                    <InputIcon class="pi pi-search" />
                    <InputText
                      class="search-input"
                      @keyup="searchRecipe"
                      :placeholder="t('admin.dashboard.search')"
                      v-model="search"
                    />
                  </IconField>
                </div>
                <div style="width: 6rem">
                  <Button
                    style="width: 100%"
                    label="Filter"
                    icon="pi pi-filter"
                    @click="toggle"
                    aria-haspopup="true"
                    aria-controls="overlay_menu"
                  />
                  <Menu
                    ref="menu"
                    id="overlay_menu"
                    :model="items"
                    :popup="true"
                  >
                    <template #submenulabel="{ item }">
                      {{ t(item.label) }}
                    </template>
                    <template #item="{ item }">
                      <div class="p-menu-item-link">
                        <span :class="item.icon" />
                        <span class="p-menu-item-label">{{
                          t(item.label)
                        }}</span>
                      </div>
                    </template>
                  </Menu>
                </div>
              </div>
              <DataTable
                class="data-table"
                :value="recipes"
                stripedRows
                tableStyle="width: 100%"
                style="border-radius: 1rem"
                :rows="rows"
                :first="(currentPage - 1) * rows"
                @page="onPage"
              >
                <Column
                  field="Title"
                  :header="t('admin.dashboard.recipe')"
                  style="width: 70%"
                >
                  <template #header
                    ><Checkbox
                      @change="setChecked"
                      v-model="selectAll"
                      :binary="true"
                      v-tooltip.top="t('admin.dashboard.selectAll')"
                  /></template>
                  <template #body="slotProps">
                    <div style="display: flex; gap: 0.5rem">
                      <Checkbox
                        v-if="!selectAll"
                        v-model="choosenRecipe"
                        :value="slotProps.data"
                      />
                      <Checkbox
                        v-else
                        @change="excludeRecipe(slotProps.data.id)"
                        v-model="checked"
                        binary
                      />
                      {{ slotProps.data.Title }}
                      <Badge
                        v-if="!slotProps.data.ProgramId"
                        severity="danger"
                        style="font-weight: bolder"
                      >
                        <i
                          class="pi pi-exclamation-triangle"
                          style="padding-right: 0.5rem"
                        />
                        {{ t('admin.dashboard.noProgram') }}
                      </Badge>
                    </div>
                  </template>
                </Column>
                <Column
                  field="status"
                  :header="t('admin.dashboard.status')"
                  v-model="active"
                  style="width: 10%"
                >
                  <template v-slot:body="slotProps">
                    <Badge
                      :severity="slotProps.data.Active ? 'success' : 'danger'"
                      :value="
                        slotProps.data.Active
                          ? t('admin.dashboard.active')
                          : t('admin.dashboard.inactive')
                      "
                    ></Badge>
                  </template>
                </Column>
                <Column
                  field="recipeProvider"
                  :header="t('admin.dashboard.recipeProvider')"
                  style="width: 10%"
                >
                  <template #body>
                    <p>Krumbein</p>
                  </template>
                </Column>
                <Column
                  field="actions"
                  :header="t('admin.dashboard.action')"
                  style="width: 10%"
                >
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-pen-to-square"
                      severity="secondary"
                      rounded
                      text
                      @click="editRecipe(slotProps.data)"
                      v-tooltip.top="t('admin.dashboard.tooltips.edit')"
                    />
                    <Button
                      @click="setRecipeActive(slotProps.data)"
                      icon="pi pi-power-off"
                      :severity="slotProps.data.Active ? 'success' : 'warn'"
                      rounded
                      text
                      v-tooltip.top="t('admin.dashboard.tooltips.switch')"
                    />
                    <Button
                      @click="openDeleteDialog(slotProps.data)"
                      icon="pi pi-trash"
                      severity="danger"
                      rounded
                      text
                      v-tooltip.top="t('admin.dashboard.tooltips.delete')"
                    />
                  </template>
                </Column>
              </DataTable>
              <div class="spinner" v-if="recipeSpinner">
                <ProgressSpinner :style="{ color: 'blue' }" />
              </div>
              <Paginator
                @page="onPage"
                :paginator="true"
                :totalRecords="count"
                :rows="rows"
              >
              </Paginator>
            </div>
          </div>
        </template>
      </Card>
      <Dialog
        :visible="changePasswordVisible"
        :header="t('admin.modalPassword.changePassword')"
        :style="{ width: '25rem' }"
        :closable="false"
        modal
      >
        <div class="inputs">
          <Password
            class="input-password"
            toggleMask
            v-model="password"
            :placeholder="t('admin.modalPassword.newPassword')"
            @keyup.enter="changePassword()"
          />
          <Password
            class="input-password"
            v-model="repeatPassword"
            :placeholder="t('admin.modalPassword.repeatPassword')"
            :feedback="false"
            toggleMask
            @keyup.enter="changePassword()"
          />
        </div>
        <div class="buttons">
          <Button
            id="save-button"
            type="button"
            :label="t('admin.monitorConfiguration.deletePopupSave')"
            @click="changePassword()"
          ></Button>
        </div>
      </Dialog>
      <Dialog
        :visible="newRecipeVisible"
        modal
        :header="t('admin.dashboard.newRecipe')"
        :style="{ width: '25rem' }"
        :closable="false"
      >
        <div class="inputs">
          <InputText
            v-model="recipeTitle"
            :placeholder="t('admin.dashboard.recipeName')"
          />
          <Select
            v-model="selectedProgram"
            :options="programs"
            optionLabel="ProgramName"
            :placeholder="t('admin.dashboard.programmSelect')"
            class="w-full md:w-56"
          />
        </div>
        <div class="buttons">
          <Button
            type="button"
            :label="t('admin.monitorConfiguration.deletePopupCancel')"
            @click="closeNewRecipeDialog"
            text
            severity="secondary"
            autofocus
          ></Button>
          <Button
            id="save-button"
            type="button"
            :label="t('admin.dashboard.createRecipe')"
            :loading="newRecipeLoading"
            @click="createRecipe()"
          ></Button>
        </div>
      </Dialog>
      <Dialog
        :visible="deleteDialog"
        :header="t('admin.monitorConfiguration.recipeDeletePopup')"
        :style="{ width: '25rem' }"
        :closable="false"
        pt:root:class="!border-0 !bg-transparent"
        pt:mask:class="backdrop-blur-sm"
      >
        <template #footer>
          <Button
            @click="closeDeleteModal"
            :label="t('admin.monitorConfiguration.deletePopupCancel')"
            text
            severity="secondary"
            autofocus
          />
          <Button
            @click="deleteRecipe"
            :label="t('admin.dashboard.deleteRecipe')"
            severity="danger"
            autofocus
            :loading="deleteRecipeLoading"
          />
        </template>
      </Dialog>
      <Dialog
        :visible="uploadFileDialog"
        :header="t('admin.dashboard.uploadRecipe')"
        :style="{ width: '60rem' }"
        :closable="false"
        pt:root:class="!border-0 !bg-transparent"
        pt:mask:class="backdrop-blur-sm"
      >
        <div class="card">
          <FileUpload
            v-model="file"
            :multiple="true"
            accept=".json"
            @select="browseFile"
            :showPreview="false"
          >
            <template #header="{ chooseCallback, uploadCallback }">
              <div
                class="flex flex-wrap justify-between items-center flex-1 gap-4"
              >
                <div class="flex gap-2">
                  <Button
                    @click="chooseCallback"
                    icon="pi pi-plus"
                    label="Choose"
                    :disabled="fileButton"
                  ></Button>
                </div>
              </div>
            </template>
            <template #content>
              <div v-if="file" class="file-content">
                <FontAwesomeIcon :icon="faFileCode" size="2x" />
                <span>
                  {{ file[0].name }}
                </span>
                <span>
                  {{ formatSize(file[0].size) }}
                </span>
                <Button
                  icon="pi pi-times"
                  class="deleteIcon"
                  rounded
                  text
                  severity="danger"
                  @click="clearFileZone"
                ></Button>
              </div>
              <div v-else>
                <span>{{ t('admin.dashboard.fileUpload') }}</span>
              </div>
            </template>
          </FileUpload>
        </div>
        <template #footer>
          <Button
            @click="closeUploadfileDialog"
            :label="t('admin.monitorConfiguration.deletePopupCancel')"
            text
            severity="secondary"
            autofocus
          />
          <Button
            type="button"
            @click="uploadFile"
            :label="t('admin.dashboard.upload')"
            autofocus
            :loading="uploadSpinner"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<style scoped>
#save-button {
  background: #0e4ca8;
  border: 1px solid #0e4ca8;
}

.action-box {
  display: flex;
  align-items: flex-start;
  background-color: #ebebeb;
  border-radius: 0.5rem;
  margin-top: 2rem;
  margin-left: 2rem;
  height: 70%;
  width: 30rem;
}

.picture {
  position: relative;
  display: flex;
  width: 100%;
  height: 20rem;
  border-radius: 1rem;
  background-image: url(../assets/Snackflexline_3332-final.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.wifi {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.card {
  overflow: hidden;
}

.load-recipe-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-recipe-button {
  width: 10rem;
}

.file-content {
  display: flex;
  border-top: 1px solid rgb(210, 210, 210);
  width: 100%;
  height: 5rem;
  align-items: center;
  justify-content: space-between;
}

.download {
  justify-content: center;
  display: flex;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search {
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
}

.search-input {
  border-radius: 0;
  width: 100%;
}

.p-dialog-mask {
  backdrop-filter: blur(10px);
}
.p-inputtext:enabled:focus {
  border-color: #0e4ca8;
}

h5 {
  margin-block-end: 0;
}

h4 {
  margin-bottom: 0;
}

.admin {
  height: 100%;
}
.table {
  width: 100%;
}
.upload-download {
  display: flex;
  width: 55%;
  gap: 0.5rem;
  align-items: center;
}
.recipe-description {
  display: flex;
  width: 45%;
  flex-direction: column;
}
.recipse-head {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #b4b4b4;
  padding-left: 1rem;
  padding-right: 1rem;
}
.recipes {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.action-box-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 3rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  margin-left: 2rem;
  margin-right: 2rem;
  gap: 2rem;
}
.inputs {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
.buttons {
  display: flex;
  justify-content: end;
  gap: 0.2rem;
  margin-top: 2rem;
}
.manage-buttons {
  position: absolute;
  bottom: 5%;
  right: 1.5%;
  gap: 0.5rem;
  display: flex;
}

.input-password {
  display: flex;
  margin-bottom: 0.5rem;
  gap: 2rem;
}
</style>
