import axios from 'axios';
import { defineStore } from 'pinia';
import type { UserStore } from '@/interfaces/stores';

export const userStore = defineStore('user', {
  state: () => ({
    user: <UserStore>{}
  }),
  actions: {
    async getUser() {
      try {
        const response = await axios.get('/api/user/store-user');
        if (response.data) {
          this.user = response.data;
        } else {
          console.error('Error: empty response!');
        }
      } catch (error) {
        console.error('Error while retrieving the user', error);
      }
    },
    clearUser() {
      this.user = {};
    }
  }
});
