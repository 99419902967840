<script setup lang="ts">
import { ref, defineProps, onMounted, watch } from 'vue';
import Select from 'primevue/select';
import { useI18n } from 'vue-i18n';

/** DATA */
const { t } = useI18n();

const props = defineProps({
  monitors: {
    type: Number,
    required: true
  },
  related: {
    type: Number,
    required: true
  }
});
const selectedMonitorID = ref();
const monitorIDs = ref<{ name: string }[]>([]);

/** METHODS */
const emit = defineEmits<{
  (e: 'monitor-selected', selectedId: { name: string }): void;
}>();

const setupMonitorIDs = (count: number): { name: string }[] => {
  const ids: { name: string }[] = [];
  for (let i = 1; i <= count; i++) {
    ids.push({ name: `Monitor ${i}` });
  }
  return ids;
};

const handleChange = () => {
  if (selectedMonitorID.value !== null) {
    emit('monitor-selected', selectedMonitorID.value);
  }
};

/** WATCH */
watch(
  () => props.monitors,
  (newId) => {
    setupMonitorIDs(newId);
  },
  { immediate: true }
);

/** HOOKS */
onMounted(() => {
  monitorIDs.value = setupMonitorIDs(props.monitors);

  if (props.related) {
    const relatedMonitor = monitorIDs.value.find(
      (item) => item.name === `Monitor ${props.related}`
    );

    if (relatedMonitor) {
      selectedMonitorID.value = relatedMonitor;
    } else {
      console.warn('Keine Option gefunden für Monitor:', props.related);
    }
  }
});
</script>

<template>
  <Select
    @change="handleChange"
    v-model="selectedMonitorID"
    :options="monitorIDs"
    optionLabel="name"
    :placeholder="t('admin.monitorConfiguration.chooseMonitor')"
    class="w-full md:w-56"
  >
    <template #value="slotProps">
      <div v-if="slotProps.value" class="flex items-center">
        <div>{{ slotProps.value.name }}</div>
      </div>
      <span v-else>
        {{ slotProps.placeholder }}
      </span>
    </template>
    <template #option="slotProps">
      <div class="flex items-center">
        <div>{{ slotProps.option.name }}</div>
      </div>
    </template>
  </Select>
</template>

<style scoped></style>
